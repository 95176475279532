import React, { useEffect, useState, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal, InputGroup, Form } from "react-bootstrap";
// import action
import { setBotStatus, setBotId } from "../../actions/Bottrade";

// import lib

import binance from "../../assets/images/binance.svg";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.svg";
import { toastAlert } from "../../lib/toastAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { getExchangeData } from "../../actions/users";
import Balance from "./balance";
export default function TradePairs(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const {
    params,
    pairsAll,
    updatePair,
    balancefetchAll,
    TotBal,
    TotLocked,
    visible, // only for grid
  } = props;
  const { bot, pair, exchange } = params;
  let strategy = {
    dayFilter: "thirtyDays",
    handleDFilter: "thirtyDays",
    byExchange: "",
    handleByExchange: "",
    handleDirection: false,
    direction: false,
    from: 0.1,
    handleFrom: 0,
    handleTo: 0,
    to: 0,
    confirmStatus: false,
    cancelStatus: false,
  };
  let botType = bot == "dca" ? "Genie" : "Grid";
  const [PairListDataTrade, setPairListDataTrade] = useState(pairsAll);
  const [PairListTrade, setPairListTrade] = useState(pairsAll);
  const [hideBal, setHideBal] = useState(false);
  const [searchby, setsearchby] = useState("USDT");
  const [searchbyinput, setsearchbyinput] = useState();
  const [sortby, Setsortby] = useState("asc");
  const [totalbalance, setTotalbalance] = useState({});
  const [errMsg, setErrMsg] = useState({});
  const [exchangeData, setExchangeData] = useState([]);
  const [strategyFilter, setSFilter] = useState(strategy);
  const [show, setShow] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const ctab = queryParams.get("tb");
  // redux
  const botData = useSelector((state) => state.botStatus);
  // function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  let {
    dayFilter,
    handleDFilter,
    byExchange,
    handleByExchange,
    handleDirection,
    direction,
    confirmStatus,
    handleFrom,
    handleTo,
    from,
    to,
    cancelStatus,
  } = strategyFilter;

  const gotoTrade = async (pair, exchange) => {
    dispatch(
      setBotId({
        positionId: "",
      })
    );
    // if (botData?.pair != pair) {
    //   dispatch(setBotStatus({ status: false, crtBot: true, pair: pair }));
    // } else {
    dispatch(
      setBotStatus({
        status: false,
        crtBot: true,
        openStatus: true,
        pair: pair,
      })
    );

    // }
    console.log(pair, "---------pair");
    console.log(params.pair, "---------params.pair");

    console.log(exchange, params.exchange, ctab, "---------params.pair");
    console.log(visible, "----------109");
    if (
      pair != params.pair ||
      exchange != params.exchange ||
      ctab == "history" ||
      ctab == "Dhistory" ||
      ctab == "Dopen" ||
      ctab == "open" ||
      visible
    ) {
      const url = `/trade/${pair}/${exchange}/${params.bot}`;
      updatePair({
        exchange: exchange,
        bot: params.bot,
        pair: pair,
      });

      navigate(url);
    }

    // window.location.href = url;
  };

  const fetchExchange = async () => {
    try {
      const { status, result } = await getExchangeData();
      if (status == "success") {
        setExchangeData(result);
      }
    } catch (err) {}
  };

  const handleClick = async (bot) => {
    const url = "/trade/" + params.pair + "/" + params.exchange + "/" + bot;
    window.location.href = url;
  };

  const searchpair = (e) => {
    e.preventDefault();
    setsearchbyinput(e.target.value);
    var searcharr = [];
    if (PairListDataTrade && PairListDataTrade.length > 0) {
      for (var i = 0; i < PairListDataTrade.length; i++) {
        var temppair =
          PairListDataTrade[i].firstCurrencySymbol +
          "/" +
          PairListDataTrade[i].secondCurrencySymbol;
        if (temppair.startsWith(e.target.value.toUpperCase())) {
          searcharr.push(PairListDataTrade[i]);
        }
        if (i == PairListDataTrade.length - 1) {
          setPairListTrade(searcharr);
        }
      }
    }
  };
  useEffect(() => {
    fetchExchange();
  }, []);
  useEffect(() => {
    setPairListDataTrade(pairsAll);
    setPairListTrade(pairsAll);
  }, [pairsAll]);

  const handleChange = (e) => {
    setErrMsg({});
    const { name, value } = e.target;
    if (/[^0-9.]/.test(value)) {
      return;
    }
    let sFilter = strategyFilter;
    let hTo = name == "handleTo" ? value : sFilter.handleTo,
      hFrom = name == "handleFrom" ? value : sFilter.handleFrom;
    setSFilter({
      ...sFilter,
      ...{ [name]: value, confirmStatus: true, cancelStatus: false },
    });
    if (hTo > 0 && hFrom > 0 && hFrom >= hTo) {
      setErrMsg({
        to: "The Maximum value of the Current profit can't be lower than its minimum value.",
      });
    }
  };
  const handleExchange = (exchange) => {
    let sFilter = strategyFilter;
    setSFilter({
      ...sFilter,
      ...{
        handleByExchange: exchange,
        confirmStatus: true,
        cancelStatus: false,
      },
    });
  };
  const hDirection = () => {
    let sFilter = strategyFilter;
    setSFilter({
      ...sFilter,
      ...{
        handleDirection: !sFilter.handleDirection,
        confirmStatus: true,
        cancelStatus: false,
      },
    });
  };
  const handleFilter = () => {
    let sFilter = strategyFilter;
    // if (exchange != sFilter.handleByExchange) {
    //   dispatch(
    //     setBotStatus({
    //       status: false,
    //       crtBot: false,
    //       openStatus: true,
    //       pair: pair,
    //     })
    //   );
    //   const url = `/trade/${pair}/${sFilter.handleByExchange}/${bot}`;
    //   updatePair({
    //     exchange: sFilter.handleByExchange,
    //     bot: bot,
    //     pair: pair,
    //   });
    //   navigate(url);
    // }
    let byExchange = "";
    if (!isEmpty(sFilter.handleByExchange)) {
      byExchange = sFilter.handleByExchange;
    }
    if (parseFloat(sFilter.handleFrom) > parseFloat(sFilter.handleTo)) {
      toastAlert("error", "To must be greater than from", "error");
      return;
    }

    let formValue = {
      ...sFilter,
      ...{
        dayFilter: sFilter.handleDFilter,
        byExchange,
        from: sFilter.handleFrom,
        to: sFilter.handleTo,
        direction: sFilter.handleDirection,
        confirmStatus: false,
        cancelStatus: false,
      },
    };
    setSFilter(formValue);
    setShow(false);
  };
  const handleCFilter = (days) => {
    setSFilter({
      ...strategyFilter,
      ...{ handleDFilter: days, confirmStatus: true },
    });
  };
  const handleClear = () => {
    let sFilter = strategyFilter;
    let formValue = {
      ...sFilter,
      ...{
        handleByExchange: "",
        handleDFilter: "thirtyDays",
        handleFrom: 0,
        handleTo: 0,
        confirmStatus: true,
        cancelStatus: true,
        handleDirection: false,
      },
    };
    setSFilter(formValue);
  };
  return (
    <>
      <div className="coin_details zindex">
        <div className="card trade_card">
          <div className="card-header">
            <ul
              class="nav nav-tabs bottab bottab_fullwidth nav-fill"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="strategies-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#strategies-tab-pane"
                >
                  Strategies
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="balance-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#balance-tab-pane"
                >
                  Balance
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="strategies-tab-pane"
                role="tabpanel"
                aria-labelledby="strategies-tab"
                tabindex="0"
              >
                <ul
                  className="nav nav-tabs bottab mt-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      // onClick={() => searchbysymbol("USDT")}
                      className={
                        params.bot == "dca" ? "nav-link active" : "nav-link"
                      }
                      id="coin_one-tab"
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#coin_one"
                      role="tab"
                      aria-controls="coin_one"
                      aria-selected="true"
                      onClick={() => handleClick("dca")}
                    >
                      DCA
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      // onClick={() => searchbysymbol("BTC")}
                      className={`${
                        params.bot == "grid" ? "nav-link active" : "nav-link"
                      }`}
                      id="coin_two-tab"
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#coin_two"
                      role="tab"
                      aria-controls="coin_two"
                      aria-selected="false"
                      onClick={() => handleClick("grid")}
                    >
                      GRID
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="strategy_filter">
                    <div className="search_inp_div">
                      <input
                        type="text"
                        placeholder="Search Pair"
                        className="form-control search_inp"
                        name="symbol"
                        value={searchbyinput}
                        onChange={searchpair}
                        autocomplete="off"
                      />
                      <i className="bi bi-search"></i>
                    </div>
                    <button className="settings_button" onClick={handleShow}>
                      <i className="bi bi-sliders2"></i>
                    </button>
                  </div>
                  {/* id coin one */}
                  {params.bot == "dca" && (
                    <div
                      className={
                        params.bot == "dca"
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="coin_one"
                      role="tabpanel"
                      aria-labelledby="coin_one-tab"
                    >
                      <div className="pair_table pair_table_chg">
                        <div className="pair_table_head">
                          {/* <div onClick={() => OnclicksortBy("quantity")}>
                          {" "}
                          Pair
                        </div>
                        <div onClick={() => OnclicksortBy("markPrice")}>
                          Direction
                        </div>
                        <div onClick={() => OnclicksortBy("profit")}>
                          Profit
                        </div> */}

                          <div> Pair</div>
                          <div>Direction</div>
                          <div>Profit</div>

                          {/* <div>Profit</div> */}
                        </div>
                        <div className="pair_table_body">
                          <Scrollbars
                            style={{ height: "1125px", width: "100%" }}
                            renderTrackVertical={({ style, ...props }) => (
                              <div
                                className="track_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  right: "0px",
                                  bottom: "0px",
                                  top: "0px",
                                  borderRadius: "1px",
                                  width: "2px",
                                }}
                              />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                              <div
                                className="thumb_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  width: "2px",
                                  borderRadius: "1px",
                                  boxShadow: "none",
                                }}
                              />
                            )}
                          >
                            {PairListTrade &&
                              PairListTrade.length > 0 &&
                              PairListTrade.map((item, key) => {
                                let cond = item.BotType.find(
                                  (el) => el == botType
                                );
                                let exCond = isEmpty(byExchange)
                                  ? true
                                  : byExchange == item.exchange;
                                let profit = !isEmpty(item.dcaProfit[dayFilter])
                                  ? item.dcaProfit[dayFilter]
                                  : 0;
                                let toCond = to <= 0 ? true : profit < to;
                                let sideDirect = !direction
                                  ? true
                                  : item.sideWay;
                                if (
                                  cond &&
                                  searchby == item.secondCurrencySymbol &&
                                  !hideBal &&
                                  profit >= from &&
                                  exCond &&
                                  toCond &&
                                  sideDirect
                                ) {
                                  return (
                                    <>
                                      <div
                                        onClick={() =>
                                          gotoTrade(
                                            item.firstCurrencySymbol +
                                              "-" +
                                              item.secondCurrencySymbol,
                                            item.exchange
                                          )
                                        }
                                        className={
                                          item.firstCurrencySymbol +
                                            "-" +
                                            item.secondCurrencySymbol ==
                                          params.pair
                                            ? "pair_table_bodyrow active"
                                            : "pair_table_bodyrow"
                                        }
                                      >
                                        <div className="pair_table_bodyrow_top">
                                          <div>
                                            {item.exchange == "Binance" && (
                                              <img src={binance} width="6%" />
                                            )}
                                            {item.exchange == "BitMart" && (
                                              <img src={bitmart} width="6%" />
                                            )}

                                            {item.exchange == "Bybit" && (
                                              <img src={bybit} width="6%" />
                                            )}

                                            <h3>
                                              {item.firstCurrencySymbol}
                                              <span>
                                                / {item.secondCurrencySymbol}
                                              </span>
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="direction_badge_wrap">
                                          <small className="badge bg_green per_change">
                                            Long
                                          </small>
                                        </div>

                                        <div className="pair_table_bodyrow_bottom">
                                          <div className="pair_table_bodyrow_bottom_right">
                                            <div>
                                              <span>
                                                <small className="greenTxt">
                                                  {profit}%
                                                </small>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* id coin two */}

                  {params.bot == "grid" && (
                    <div
                      className={`${
                        params.bot == "grid"
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }`}
                      id="coin_two"
                      role="tabpanel"
                      aria-labelledby="coin_two-tab"
                    >
                      <div className="pair_table pair_table_chg">
                        <div className="pair_table_head">
                          <div> Pair</div>
                          <div>Direction</div>
                          <div>Profit</div>
                          {/* <div>Profit</div> */}
                        </div>
                        <div className="pair_table_body">
                          <Scrollbars
                            style={{ height: "1125px" }}
                            renderTrackVertical={({ style, ...props }) => (
                              <div
                                className="track_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  right: "2px",
                                  bottom: "2px",
                                  top: "2px",
                                  borderRadius: "1px",
                                  width: "2px",
                                }}
                              />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                              <div
                                className="thumb_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  width: "2px",
                                  borderRadius: "1px",
                                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                }}
                              />
                            )}
                          >
                            {PairListTrade &&
                              PairListTrade.length > 0 &&
                              PairListTrade.map((item, key) => {
                                let cond = item.BotType.find(
                                  (el) => el == botType
                                );
                                let exCond = isEmpty(byExchange)
                                  ? true
                                  : byExchange == item.exchange;
                                let profit = !isEmpty(
                                  item.gridProfit[dayFilter]
                                )
                                  ? item.gridProfit[dayFilter]
                                  : 0;
                                let toCond = to <= 0 ? true : profit < to;
                                let sideDirect = !direction
                                  ? true
                                  : item.sideWay;
                                if (
                                  cond &&
                                  searchby == item.secondCurrencySymbol &&
                                  !hideBal &&
                                  profit >= from &&
                                  exCond &&
                                  toCond &&
                                  sideDirect
                                ) {
                                  return (
                                    <>
                                      <div
                                        onClick={() =>
                                          gotoTrade(
                                            item.firstCurrencySymbol +
                                              "-" +
                                              item.secondCurrencySymbol,
                                            item.exchange
                                          )
                                        }
                                        className={
                                          item.firstCurrencySymbol +
                                            "-" +
                                            item.secondCurrencySymbol ==
                                          params.pair
                                            ? "pair_table_bodyrow active"
                                            : "pair_table_bodyrow"
                                        }
                                      >
                                        <div className="pair_table_bodyrow_top">
                                          <div>
                                            {item.exchange == "Binance" && (
                                              <img src={binance} width="6%" />
                                            )}
                                            {item.exchange == "BitMart" && (
                                              <img src={bitmart} width="6%" />
                                            )}

                                            {item.exchange == "Bybit" && (
                                              <img src={bybit} width="6%" />
                                            )}

                                            <h3>
                                              {item.firstCurrencySymbol}
                                              <span>
                                                / {item.secondCurrencySymbol}
                                              </span>
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="direction_badge_wrap">
                                          <small className="badge bg_grey per_change">
                                            {item.sideWay && "Sideways"}
                                            {!item.sideWay && "-"}
                                          </small>
                                        </div>

                                        <div className="pair_table_bodyrow_bottom">
                                          <div className="pair_table_bodyrow_bottom_right">
                                            <div>
                                              <span>
                                                <small className="greenTxt">
                                                  {profit}%
                                                </small>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Balance
                params={params}
                pairsAll={pairsAll}
                balancefetchAll={balancefetchAll}
                TotBal={TotBal}
                TotLocked={TotLocked}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="primary_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${bot.toUpperCase()} strategies filter`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="backtest_period">
            <p>Backtest period</p>
            <div className="backtest_periodbtn_grp">
              <button
                onClick={() => handleCFilter("thirtyDays")}
                className={handleDFilter == "thirtyDays" && "selected"}
              >
                30d
              </button>
              {/* <button
                onClick={() => handleCFilter("sevenDays")}
                className={handleDFilter == "sevenDays" && "selected}
              >
                7d
              </button>
              <button
                onClick={() => handleCFilter("threeDays")}
                className={handleDFilter == "threeDays" && "selected}
              >
                3d
              </button> */}
            </div>
          </div>
          <div className="filter_panel">
            <div className="filter_panel_row">
              <label>By exchanges</label>
              <div className="filter_btn_grp">
                {exchangeData &&
                  exchangeData.length > 0 &&
                  exchangeData.map((item, index) => {
                    return (
                      <button
                        onClick={() => handleExchange(item.exchange)}
                        className={
                          handleByExchange == item.exchange && "selected"
                        }
                      >
                        {item.exchange}
                      </button>
                    );
                  })}
                {/* <button
                  onClick={() => handleExchange("Binance")}
                  className={handleByExchange == "Binance" && "selected"}
                >
                  Binance
                </button>
                <button
                  onClick={() => handleExchange("BitMart")}
                  className={handleByExchange == "BitMart" && "selected"}
                >
                  Bitmart
                </button>

                <button
                  onClick={() => handleExchange("Bybit")}
                  className={handleByExchange == "Bybit" && "selected"}
                >
                  Bybit
                </button> */}

                {/* <button>Kucoin</button>
                 */}
              </div>
            </div>
            {bot.toUpperCase() == "GRID" && (
              <div className="filter_panel_row">
                <label>Direction</label>
                <div className="filter_btn_grp">
                  <button
                    onClick={() => hDirection()}
                    className={handleDirection && "selected"}
                  >
                    Sideway
                  </button>
                </div>
              </div>
            )}
            <div className="filter_panel_row">
              <label>Custom profit</label>
              <div className="filter_inp_grp createbot_box m-0">
                <div className="inputgrp_box">
                  <label className="grey">From, %</label>
                  <InputGroup className="w-100">
                    <Form.Control
                      aria-label="Username"
                      name="handleFrom"
                      aria-describedby="basic-addon1"
                      value={handleFrom}
                      onChange={(e) => handleChange(e)}
                    />
                  </InputGroup>
                </div>
                <div className="inputgrp_box">
                  <label className="grey">To, %</label>
                  <InputGroup
                    className={
                      errMsg && errMsg.to ? "w-100 red_border" : "w-100"
                    }
                  >
                    <Form.Control
                      aria-label="Username"
                      name="handleTo"
                      aria-describedby="basic-addon1"
                      value={handleTo}
                      onChange={(e) => handleChange(e)}
                    />
                  </InputGroup>
                  <span
                    className={
                      errMsg && errMsg.to
                        ? "text-danger error_msg"
                        : "text-danger"
                    }
                  >
                    {errMsg && errMsg.to}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* {!confirmStatus && !cancelStatus && (
            <div className="filter_panel_btn">
              <button class="continue border_btn" onClick={() => handleClear()}>
                Clear filter
              </button>
            </div>
          )}
          {cancelStatus && (
            <div className="filter_panel_btn">
              <button class="continue border_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          )} */}

          <div className="filter_panel_btn">
            <button class="continue border_btn" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>

          <div className="filter_panel_btn">
            <button class="continue border_btn" onClick={() => handleClear()}>
              Clear filter
            </button>
            <button class="continue" onClick={() => handleFilter()}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
