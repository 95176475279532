// import package
import React, { useEffect, useState, Fragment, useContext } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import action
import {
  openBotApi,
  closeBotApi,
  openSB,
  getGCHistory,
} from "../../actions/gridbot";
import { curBotDetail } from "../../actions/gridbot";

// import lib
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { dateHours, dateHoursclose, momentFormat } from "../../lib/dateFilter";
import { firstCapital } from "../../lib/String";
import { dateHourswithTime } from "../../lib/dateFilter";
import { unrealizedPnl } from "../../lib/calculation";
import SocketContext from "../Context/SocketContext";
import isEmpty from "../../lib/isEmpty";
import { setBotStatus } from "../../actions/Bottrade";

const BotHistory = (props) => {
  const navigate = useNavigate();

  const { pair, exchange } = useParams();
  const socketContext = useContext(SocketContext);
  // props
  const {
    // exchange,
    // pair,
    setOrderData,
    markPrice,
    markResult,
    updatePair,
    updateParams,
    FeeandDecimal,
  } = props;

  //   state
  const [oBotD, setOBotD] = useState([]);
  const [cBotD, setCBotD] = useState([]);
  const [curNav, setCurNav] = useState("spot");
  const [sumProfit, setSumprofit] = useState({
    sumpnl: 0,
    sumpnlprc: 0,
    investment: 0,
  });
  const [tCurVal, setTotCur] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;
  // redux-state
  const dispatch = useDispatch();

  // function
  const openBot = async () => {
    try {
      console.log(exchange, "------63");
      let reqData = {
        exchange: exchange,
      };
      const { status, result, pairData } = await openBotApi(reqData);
      console.log(result, "-------51", curNav);
      let botList = [],
        cVal = 0;
      if (status == "success" && curNav == "spot") {
        let pairName = pair.replace("-", "");
        console.log(pairName, "------73");
        if (result && result.length > 0) {
          let curBot = result.find((el) => el.pairName === pairName);
          console.log(curBot, "-------76");
          if (curBot) {
            curBotDetail(
              {
                action: "detail",
                ...curBot,
              },
              dispatch
            );
          } else {
            curBotDetail(
              {
                action: "create",
                pairName,
                filled: [],
              },
              dispatch
            );
          }

          for (let [index, item] of result.entries()) {
            let spotPair = pairData.find(
              (el) => el._id.toString() == item.pairId.toString()
            );
            let curVal = calcCV(item.initial, item.actives, spotPair);

            let pnl = calcPnl(item.initial, item.actives, spotPair, curVal);

            cVal += curVal;

            let investment = calInv(item.initial);
            // let tPnl = parseFloat(item.totalPnl) + parseFloat(pnl.totalpnl);
            // let tPnlPct =
            //   parseFloat((item.totalPnl / investment) * 100) +
            //   parseFloat(pnl.totalPnlPct);
            let tPnl = parseFloat(pnl.totalpnl);
            let tPnlPct = parseFloat(pnl.totalPnlPct);
            botList.push({
              ...item,
              pnl: tPnl,
              pnlPct: tPnlPct,
              curVal: curVal,
              index: index,
            });
          }
          setTotCur(cVal);
          setOBotD(botList);
        } else {
          curBotDetail(
            {
              action: "create",
              pairName,
              filled: [],
            },
            dispatch
          );
          setTotCur(cVal);
          setOBotD(botList);
        }
      }
    } catch (err) {}
  };
  useEffect(() => {
    socketContext.socket.on("openGridSocket", (result) => {
      console.log(result, "---------106", pair.replace(/\-/g, ""), pair);
      console.log(exchange, "---------1067", curNav);
      if (exchange == result.exchange) {
        if (pair.replace(/\-/g, "") == result.pairName) {
          console.log(result, "--------146");
          let pName = result.pairName;
          let curBot = result.data.find((el) => el.pairName === pName);
          if (curBot) {
            curBotDetail(
              {
                action: "detail",
                ...curBot,
              },
              dispatch
            );
          } else {
            curBotDetail(
              {
                action: "closed",
                pName,
                breakingEvent: 0,
                stopLoss: 0,
                filled: [],
              },
              dispatch
            );
          }
        }
        let botList = [],
          cVal = 0;
        for (let [index, item] of result.data.entries()) {
          let spotPair = result.pairData.find(
            (el) => el._id.toString() == item.pairId.toString()
          );
          let curVal = calcCV(item.initial, item.actives, spotPair);

          let pnl = calcPnl(item.initial, item.actives, spotPair, curVal);

          cVal += curVal;

          let investment = calInv(item.initial);
          // let tPnl = parseFloat(item.totalPnl) + parseFloat(pnl.totalpnl);
          // let tPnlPct =
          //   parseFloat((item.totalPnl / investment) * 100) +
          //   parseFloat(pnl.totalPnlPct);
          let tPnl = parseFloat(pnl.totalpnl);
          let tPnlPct = parseFloat(pnl.totalPnlPct);
          botList.push({
            ...item,
            pnl: tPnl,
            pnlPct: tPnlPct,
            curVal: curVal,
            index: index,
          });
        }
        setTotCur(cVal);
        setOBotD(botList);
      }
    });
  }, [pair, exchange]);
  useEffect(() => {
    socketContext.socket.on("historyGridSocket", (result) => {
      console.log(result, "---------161", pair.replace(/\-/g, ""));
      console.log(exchange, "--------193");
      if (
        // pair.replace(/\-/g, "") == result.pairName &&
        exchange == result.exchange
      ) {
        if (result && result.data && result.data.length > 0) {
          let totalPnl = 0,
            totalPnlPct = 0,
            botList = [],
            totInv = 0;
          for (let [index, item] of result.data.entries()) {
            let uRealized = item.uRealized;
            let investment = calInv(item.initial);
            let curVal = calcUCV(item, item.initial, item.actives, uRealized);
            let pnl = calUPnl(item, curVal, investment, uRealized);
            let tPnl = parseFloat(item.totalPnl) + parseFloat(pnl.totalpnl);
            let tPnlPct =
              parseFloat((item.totalPnl / investment) * 100) +
              parseFloat(pnl.totalPnlPct);
            botList.push({
              ...item,
              totalPnl: tPnl,
              totalPnlPct: tPnlPct,
              index: index,
            });

            // totalPnl = totalPnl + item.totalPnl;
            // totalPnlPct = totalPnlPct + item.totalPnlPct;
            totalPnl += tPnl ? tPnl : 0;
            totalPnlPct += tPnlPct ? tPnlPct : 0;
            totInv += investment;
          }
          setCBotD(botList);
          setSumprofit({
            sumpnlprc: totalPnlPct,
            sumpnl: totalPnl,
            investment: totInv,
          });
          // dispatch(setBotStatus({ status: false }));
        }
      }
    });
  }, [pair, exchange]);

  useEffect(() => {
    // if (!isEmpty(exchange)) {
    // socket
    // let socketTicker = "socketTicker" + exchange;
    // socketContext[socketTicker].on("marketPrice", (result) => {
    setOBotD((el) => {
      let botList = [],
        cVal = 0;
      el &&
        el.map((item) => {
          if (item.pairId == markResult.pairId) {
            let spotPair = { markPrice: markResult.data.markPrice };
            let curVal = calcCV(item.initial, item.actives, spotPair);
            let pnl = calcPnl(item.initial, item.actives, spotPair, curVal);
            cVal += curVal;

            let investment = calInv(item.initial);
            // let tPnl = parseFloat(item.totalPnl) + parseFloat(pnl.totalpnl);
            // let tPnlPct =
            //   parseFloat((item.totalPnl / investment) * 100) +
            //   parseFloat(pnl.totalPnlPct);
            let tPnl = parseFloat(pnl.totalpnl);
            let tPnlPct = parseFloat(pnl.totalPnlPct);
            botList.push({
              ...item,
              pnl: tPnl,
              pnlPct: tPnlPct,
              curVal: curVal,
            });
          } else {
            cVal += item.curVal;
            botList.push(item);
          }
        });
      setTotCur(cVal);
      return botList;
    });
    // });
    // return () => {
    //   socketContext[socketTicker].off("marketPrice");
    // };
    // }
  }, [markResult]);

  const calcPnl = (initial, actives, spotData, curVal) => {
    try {
      let investment = calPnlInv(actives);
      let totalpnl = 0,
        totalPnlPct = 0;
      if (actives && actives.length > 0) {
        let mPrice = spotData.markPrice;
        console.log(actives, "-------287");
        console.log(mPrice, "--------288");
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd,
              pnl;
            if (exchange == "BitMart") {
              pnl =
                mPrice * prevOrd.quantity -
                (prevOrd.price * prevOrd.quantity + prevOrd.fee);
            } else if (exchange == "Binance") {
              if (prevOrd.quantity == el.quantity) {
                let fee = !isEmpty(prevOrd.fee) ? prevOrd.fee : 0;
                pnl =
                  mPrice * el.quantity - fee - prevOrd.price * prevOrd.quantity;
              } else {
                pnl =
                  mPrice * prevOrd.execQty - prevOrd.price * prevOrd.quantity;
              }
            } else {
              pnl = mPrice * prevOrd.execQty - prevOrd.price * prevOrd.quantity;
            }
            totalpnl += pnl;
          }
        }
      }
      totalPnlPct = totalpnl ? (totalpnl / investment) * 100 : 0;
      return { totalpnl, totalPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const calcPnl_OLD = (initial, actives, spotData, curVal) => {
    try {
      let investment = calInv(initial);
      let totalpnl = 0,
        totalPnlPct = 0;
      if (actives && actives.length > 0) {
        console.log(actives, "---------278", spotData);
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd,
              pnl;
            if (exchange == "Bitmart") {
              pnl =
                spotData.markPrice * el.quantity -
                (prevOrd.price * prevOrd.quantity + prevOrd.fee);
            } else if (exchange == "Binance") {
              if (
                isEmpty(prevOrd?.feeCoin) ||
                prevOrd?.feeCoin == spotData.firstCurrencySymbol
              ) {
                pnl =
                  spotData.markPrice * el.quantity -
                  prevOrd.price * prevOrd.quantity;
              } else {
                pnl =
                  spotData.markPrice * prevOrd.quantity -
                  prevOrd.fee -
                  prevOrd.price * prevOrd.quantity;
              }
            } else {
              pnl =
                spotData.markPrice * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            console.log(pnl, "------302");
            // let pnl =
            //   spotData.markPrice * el.quantity -
            //   prevOrd.price * prevOrd.quantity;
            totalpnl = totalpnl + pnl;

            totalPnlPct = (curVal / investment) * 100 - 100;

            // totalPnlPct + (pnl / (prevOrd.price * prevOrd.quantity)) * 100;
            // totalPnlPct + (curVal / investment) * 100 - 100;
          }
        }
      }
      console.log(totalpnl, "---------315", totalPnlPct);
      return { totalpnl, totalPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const calcCV = (initial, actives, spotData) => {
    try {
      let totalpnl = 0,
        totalInv = 0;

      if (initial && initial.length > 0) {
        for (let el of initial) {
          let ov = el.price * el.quantity;
          if (exchange == "BitMart" && el.side == "buy") {
            let oFee = parseFloat(FeeandDecimal?.tFee?.buyMaker) * el.quantity;
            oFee = oFee * el.price;
            ov = ov + oFee;
          }
          totalInv = totalInv + ov;
        }
      }
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl =
              spotData.markPrice * el.quantity -
              prevOrd.price * prevOrd.quantity;
            totalpnl = totalpnl + pnl;
          }
        }
      }
      return totalInv + totalpnl;
    } catch (err) {
      return 0;
    }
  };
  // Function to filter dates after today
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    return date <= currentDate;
  };
  const handleDate = (update) => {
    setDateRange(update);
    if (update[0] != null && update[1] != null) {
      closeBot(update[0], update[1]);
    }
  };
  const closeBot = async (sDate, lDate) => {
    try {
      let reqData = {
        exchange: exchange,
        sDate,
        lDate,
      };
      const { status, result } = await closeBotApi(reqData);
      if (status == "success") {
        if (result && result.length > 0) {
          let totalPnl = 0,
            totalPnlPct = 0,
            botList = [],
            totInv = 0;
          for (let [index, item] of result.entries()) {
            let uRealized = item.uRealized;
            let investment = calInv(item.initial);
            let curVal = calcUCV(item, item.initial, item.actives, uRealized);
            let pnl = calUPnl(item, curVal, investment, uRealized);
            let tPnl = parseFloat(item.totalPnl) + parseFloat(pnl.totalpnl);
            let tPnlPct =
              parseFloat((item.totalPnl / investment) * 100) +
              parseFloat(pnl.totalPnlPct);
            botList.push({
              ...item,
              totalPnl: tPnl,
              totalPnlPct: tPnlPct,
              index: index,
            });

            // totalPnl = totalPnl + item.totalPnl;
            // totalPnlPct = totalPnlPct + item.totalPnlPct;
            totalPnl += tPnl ? tPnl : 0;
            totalPnlPct += tPnlPct ? tPnlPct : 0;
            totInv += investment;
          }
          setCBotD(botList);
          setSumprofit({
            sumpnlprc: totalPnlPct,
            sumpnl: totalPnl,
            investment: totInv,
          });
        }
      }
    } catch (err) {}
  };

  const calcUCV = (item, initial, actives, uRealized) => {
    try {
      let totalpnl = 0,
        totalInv = 0;

      if (initial && initial.length > 0) {
        for (let el of initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl = 0;
            if (!isEmpty(uRealized)) {
              pnl =
                uRealized.price * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            if (!isEmpty(item.markPrice) && item.markPrice > 0) {
              pnl =
                item.markPrice * el.quantity - prevOrd.price * prevOrd.quantity;
            }
            totalpnl = totalpnl + pnl;
          }
        }
      }

      return totalInv + totalpnl;
    } catch (err) {
      return 0;
    }
  };

  const calUPnl = (item, curVal, investment, uRealized) => {
    try {
      let actives = item.actives;
      let totalpnl = 0,
        totalPnlPct = 0;
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl = 0;
            if (!isEmpty(uRealized)) {
              pnl =
                uRealized.price * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            if (!isEmpty(item.markPrice) && item.markPrice > 0) {
              pnl =
                item.markPrice * el.quantity - prevOrd.price * prevOrd.quantity;
            }
            totalpnl = totalpnl + pnl;

            totalPnlPct = (curVal / investment) * 100 - 100;
          }
        }
      }
      return { totalpnl, totalPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const rowActive = (data) => {
    const rowElement = document.getElementById(`row-${data.index}`);
    // Remove "selected-row-active" from all existing rows **except** the current one
    const allRows = document.querySelectorAll(".selected-row-active");
    for (const existingRow of allRows) {
      if (existingRow !== rowElement) {
        existingRow.classList.remove("selected-row-active");
      }
    }
    // Toggle "selected-row-active" on the current row
    rowElement.classList.toggle("selected-row-active");
  };
  const handleBotRow = (data, type) => {
    // rowActive(data);
    curBotDetail(
      {
        action: "detail",
        ...data,
      },
      dispatch
    );
    updateParams({
      exchange: data.exchange,
      bot: "grid",
      pair: data.firstCurrency + "-" + data.secondCurrency,
    });
    // updatePair({
    //   exchange: data.exchange,
    //   bot: "grid",
    //   pair: data.firstCurrency + "-" + data.secondCurrency,
    //   curNav: type,
    // });
    // window.location.href = `/trade/${
    //   data.firstCurrency + "-" + data.secondCurrency
    // }/${data.exchange}/grid`;

    navigate(
      `/trade/${data.firstCurrency + "-" + data.secondCurrency}/${
        data.exchange
      }/grid`
    );

    dispatch(setBotStatus({ status: false, openStatus: true }));
    setTimeout(() => {
      setCurNav(type);
    }, [1000]);
  };
  const handleClosedBot = (data, type) => {
    // rowActive(data);
    curBotDetail(
      {
        action: "detail",
        ...data,
      },
      dispatch
    );
    // updatePair({
    //   exchange: data.exchange,
    //   bot: "grid",
    //   pair: data.firstCurrency + "-" + data.secondCurrency,
    // });
    updateParams({
      exchange: data.exchange,
      bot: "grid",
      pair: data.firstCurrency + "-" + data.secondCurrency,
    });

    navigate(
      `/trade/${data.firstCurrency + "-" + data.secondCurrency}/${
        data.exchange
      }/grid`
    );
    dispatch(setBotStatus({ status: false, openStatus: true }));
    setTimeout(() => {
      setCurNav(type);
    }, [1000]);
  };
  $(".rdt_TableRow").click(function () {
    $(".rdt_TableRow").removeClass("selected-row-active");
    $(this).addClass("selected-row-active");
  });
  const calcInvt = (filled, secondCurrency, decimal, type) => {
    try {
      let totalInv = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          let ov = el.price * el.quantity;
          if (exchange == "BitMart" && el.side == "buy") {
            let oFee = parseFloat(FeeandDecimal?.tFee?.buyMaker) * el.quantity;
            oFee = oFee * el.price;
            ov = ov + oFee;
          }
          totalInv = totalInv + ov;
        }
      }

      if (type == "download") {
        return `${toFixedDown(totalInv, decimal)} ${secondCurrency}`;
      }
      return (
        <p className="mb-0">
          {toFixedDown(totalInv, decimal)}{" "}
          <span className="greyTxt">{secondCurrency}</span>
        </p>
      );
    } catch (err) {
      if (type == "download") {
        return `${toFixedDown(0, decimal)} ${secondCurrency}`;
      }
      return (
        <p className="mb-0">
          {toFixedDown(0, decimal)}{" "}
          <span className="greyTxt">{secondCurrency}</span>
        </p>
      );
    }
  };
  const calPnlInv = (actives) => {
    try {
      let totalInv = 0;
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            totalInv = totalInv + prevOrd.price * prevOrd.quantity;
          }
        }
      }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };
  const calInv = (filled) => {
    try {
      let totalInv = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };

  const calcFilledInvt = (initial, totalpnl, secondCurrency, type) => {
    let totalInv = 0;
    try {
      if (initial && initial.length > 0) {
        for (let el of initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
    } catch (err) {
      // return 0;
    }

    if (type == "download") {
      return `${toFixed(totalInv + totalpnl, 2)} ${secondCurrency}`;
    }
    return (
      <div>
        <p className="mb-0">
          {toFixed(totalInv + totalpnl, 2)}{" "}
          <span className="greyTxt">{secondCurrency}</span>
        </p>
      </div>
    );
  };

  const handleNav = (val) => {
    setCurNav(val);
  };

  useEffect(() => {
    console.log(curNav, "---------603", pair);
    if (curNav == "spot") {
      openBot();
    }
    closeBot(startDate, endDate);
  }, [pair, exchange, curNav, FeeandDecimal, dateRange]);

  const openBotCol = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type">
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{`${row.firstCurrency} / ${row.secondCurrency}`}</p>
            <div className="table_pairtype">
              <p className="mb-0">GRID</p>
              {/* <p className="mb-0">Long</p> */}
            </div>
          </div>
        );
      },
    },
    {
      name: "Investment",
      selector: (row) => {
        return calcInvt(row.initial, row.secondCurrency, 1, "history");
      },
    },
    {
      name: "Current Value",
      selector: (row) => {
        return (
          <div>
            <p className="mb-0">
              {toFixed(row.curVal, 2)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
      width: "15%",
    },
    // {
    //   name: "Bot profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Avg. daily profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Total PnL",
      selector: (row) => {
        return (
          <Fragment>
            <p className={row.pnlPct > 0 ? "greenTxt mb-0" : "redText mb-0"}>
              {toFixedDown(row.pnlPct, 2)} %
            </p>
            <p className="mb-0">
              {toFixedDown(row.pnl, 2)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </Fragment>
        );
      },
      width: "15%",
    },
    {
      name: "Txns / Time",
      selector: (row) => {
        console.log(dateHours(row.createdAt), "------------634", row.createAt);
        return (
          <div>
            <p className="mb-2"> {row?.txCnt} </p>
            <p className="mb-0 greyTxt">{dateHours(row.createdAt)}</p>
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div>
            <p
              className={row && row.status == "close" ? "redText" : "greenTxt"}
            >
              {row && row.status}{" "}
              {/* <p className="greyTxt">{toFixed(0, 2)}{row.secondCurrency}</p> */}
            </p>
          </div>
        );
      },
    },
    {
      name: "Close",
      selector: (row) => {
        return (
          <Fragment>
            <button
              className="primary_btn primary_black_btn mt-0"
              onClick={() => openSB({ type: "open", row }, dispatch)}
            >
              <img
                src={require("../../assets/images/ban_icon.png")}
                alt="Icon"
                className="img-fluid"
                width="18px"
              />
            </button>
          </Fragment>
        );
      },
      width: "80px",
    },
  ];

  const closeBotCol = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type">
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{`${row.firstCurrency} / ${row.secondCurrency}`}</p>
            <div className="table_pairtype">
              <p className="mb-0">GRID</p>
              {/* <p className="mb-0"> Long</p> */}
            </div>
          </div>
        );
      },
    },
    {
      name: "Investment",
      selector: (row) => {
        return calcInvt(row.initial, row.secondCurrency, 2, "history");
      },
    },
    {
      name: "Current Value",
      selector: (row) => {
        return calcFilledInvt(
          row.initial,
          row.totalPnl,
          row.secondCurrency,
          "history"
        );
      },
    },
    // {
    //   name: "Bot profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Avg. daily profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Total PNL",
      selector: (row) => {
        return (
          <div>
            <p
              className={row.totalPnlPct < 0 ? "redText mb-0" : "greenTxt mb-0"}
            >
              {toFixed(row.totalPnlPct, 2)} %
            </p>
            <p className="mb-0">
              {toFixed(row.totalPnl, 2)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Txns / Time",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{row?.txCnt}</p>
            <p className="mb-0 greyTxt">
              {dateHoursclose(row.createdAt, row.updatedAt)}
            </p>
          </div>
        );
      },
    },
    {
      name: (
        <div>
          <p className="mb-0">Status</p>
        </div>
      ),
      selector: (row) => {
        return (
          <div>
            <p className="greyTxt mb-2">{firstCapital(row.status)}</p>
            <p className="tablebot_status mb-0">
              {dateHourswithTime(row.closedDate)}
            </p>
          </div>
        );
      },
    },
  ];

  let cSumCV = 0;
  if (cBotD && cBotD.length > 0) {
    for (let b of cBotD) {
      let totalInv = 0;
      if (b.initial && b.initial.length > 0) {
        for (let el of b.initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      cSumCV = cSumCV + (totalInv + b.totalPnl);
    }
  }
  const handleDownload = async () => {
    let csvData = [
      [
        "Ex.",
        "Bot",
        "Pair / Bot type",
        "Investment",
        "Current Value",
        "Total PNL %",
        "Total PNL",
        "Txns / Time",
        "Status",
        "Closed Time",
      ],
    ];
    for (let item of cBotD) {
      let arrData = [
        firstCapital(item.exchange),
        "grid",
        `${item.firstCurrency} / ${item.secondCurrency}`,
        calcInvt(item.initial, item.secondCurrency, 2, "download"),
        calcFilledInvt(
          item.initial,
          item.totalPnl,
          item.secondCurrency,
          "download"
        ),
        `${toFixed(item.totalPnlPct, FeeandDecimal?.priceDecimal)} %`,
        toFixed(item.totalPnl, 2),
        `${item?.txCnt} ${dateHours(item.createdAt)}`,
        firstCapital(item.status),
        dateHourswithTime(item.closedDate),
      ];
      csvData.push(arrData);
    }

    // Convert the data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "tradehistory-grid.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  return (
    <div className="card trade_card trade_history_card">
      <div className="card-header">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={curNav == "spot" ? "nav-link active" : "nav-link"}
              onClick={() => handleNav("spot")}
            >
              Spot Bots ({oBotD && oBotD.length})
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={curNav == "history" ? "nav-link active" : "nav-link"}
              onClick={() => handleNav("history")}
            >
              Bot History
            </button>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between flex-wrap my-2">
          <div className="bot_profit flex-wrap w-100 justify-content-between align-items-center">
            {curNav == "spot" && (
              <p>
                <span>Sum, current value</span> $ {toFixed(tCurVal, 2)}
              </p>
            )}

            {curNav == "history" && (
              <>
                <div className="d-flex flex-wrap gap-3">
                  <p>
                    <span>Sum, current value</span> $ {toFixed(cSumCV, 2)}
                  </p>
                  <p>
                    <span>Sum, bot profit</span> ${" "}
                    {toFixed(sumProfit.sumpnl, 2)}{" "}
                    <span
                      className={
                        sumProfit.sumpnlprc > 0 ? "greenTxt" : "redText"
                      }
                    >
                      {` ${toFixed(sumProfit.sumpnlprc, 2)}`}%
                    </span>
                  </p>
                  <p>
                    <span>Sum, Investment</span> ${" "}
                    {toFixed(sumProfit.investment, 2)}
                  </p>
                </div>
                <div className="filter_flx filter_flx_chg align-items-center date_cal">
                  <div className="dat">
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => handleDate(update)}
                      popperPlacement="left-end"
                      filterDate={filterPassedDate} // Apply the filter function
                    />
                  </div>
                  <div className="svg_i">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="DateRangeIcon"
                    >
                      <path d="M9 11H7v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V9h14z"></path>
                    </svg>
                  </div>
                  <div
                    className="delete_cal"
                    onClick={() => setDateRange([null, null])}
                  >
                    <img
                      src={require(`../../assets/images/Calendar-Delete.png`)}
                      alt="Icon"
                      className="img-fluid"
                      width="18px"
                    />
                  </div>
                  <button class="continue w-auto" onClick={handleDownload}>
                    Download CSV
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          {/* <div
            className={
              curNav == "spot" ? "tab-pane active show" : "tab-pane fade"
            }
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          > */}
          {curNav == "spot" && (
            <div className="primary_table primary_table_full_height">
              <DataTable
                columns={openBotCol}
                data={oBotD}
                pagination
                onRowClicked={(data) => handleBotRow(data, "spot")}
              />
            </div>
          )}
          {/* </div> */}
          {/* <div
            className={
              curNav == "history" ? "tab-pane active show" : "tab-pane fade"
            }
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          > */}
          {curNav == "history" && (
            <div className="primary_table primary_table_chg  primary_table_full_height">
              <DataTable
                columns={closeBotCol}
                data={cBotD}
                pagination
                onRowClicked={(data) => handleClosedBot(data, "history")}
              />
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default BotHistory;
