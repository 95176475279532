import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form, Modal } from "react-bootstrap";
import Header from "../Header";
import ExchangeBalance from "./ExchangeBalance";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import context
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import bybit from "../../assets/images/bybit.svg";
import bitmart from "../../assets/images/bitmart.png";
import {
  getBalanceData,
  getExchangeData,
  setBalanceDataAll,
} from "../../actions/users";
import isEmpty from "../../lib/isEmpty";
import { useNavigate } from "react-router-dom";
import {
  currentMarketPrice,
  getTradeManualPair,
  manualOrderPlace,
  getManualPosition,
  getRecentOrders,
  bulkEdit,
  getlockedAmt,
} from "../../actions/Bottrade";
import validation from "./validation";
import { toastAlert } from "../../lib/toastAlert";
import { calPrice, toFixed, toFixedDown } from "../../lib/roundOf";
import {
  dateHours,
  dateHoursclose,
  dateTimeFormat,
} from "../../lib/dateFilter";
import SocketContext from "../Context/SocketContext";
import Chart from "../Chart/Chart";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const intitalChartdata = {
  stoploss: {},
  takeprofit: {},
  avgData: [],
  avgPrice: {},
};
const formOrder = {
  mOrder: true,
  lOrder: false,
};

const orderData = {
  pair: "",
  buyPair: "",
  quantity: "",
  takeProfitPercentage: "",
  takeProfitPrice: "",
  pairData: "",
  estTot: "",
  formType: "tradeManual",
  firstCurrency: "BTC",
};
const marketData = {
  currentPrice: "",
  marketPair: "",
};
const loadForm = {
  sellLoader: false,
  positionLoader: false,
  loaderIndex: 0,
};
export default function TradeManual(props) {
  const { params } = props;
  const { exchange } = params;
  let history = useNavigate();
  const socketContext = useContext(SocketContext);

  const secondCurrency = "USDT";
  // state
  const [show, setShow] = useState(false);
  const [showPairChart, setShowPairChart] = useState(false);
  const [showSellConfirmation, setShowSellConfirmation] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const [showNoTarget, setShowNoTarget] = useState(false);
  const [selectedValue, setSelectedValue] = useState(exchange);
  const [balanceData, setBalanceData] = useState(0);
  const [PairListData, setPairListData] = useState();
  const [exchangeData, setExchangeData] = useState();
  const [exchangeSearch, setExchangeSearch] = useState();
  const [sbExc, setSBExc] = useState("");
  const [orderType, setOrderType] = useState(formOrder);
  const [marketValue, setMarketValue] = useState(marketData);
  const [recentType, setRecentType] = useState("sell");
  const [formValue, setFormValue] = useState(orderData);
  const [searchbyinput, setsearchbyinput] = useState();
  const [recentOrders, setRecentOrders] = useState([]);
  const [openPosition, setOpenPosition] = useState([]);
  const [loader, setLoader] = useState();
  const [loaderForm, setLoaderForm] = useState(loadForm);
  const [PairList, setPairList] = useState();
  const [infoDet, setInfoDet] = useState({});
  const [validateError, setValidateError] = useState({});
  const [viewData, setViewData] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [chartData, setChartData] = useState({});
  const [sellData, setSellData] = useState({});
  const [TotalUsdtBal, setTotalUsdtBal] = useState(0);

  const { mOrder, lOrder } = orderType;
  const { sellLoader, positionLoader, loaderIndex } = loaderForm;
  const {
    pair,
    quantity,
    takeProfitPercentage,
    buyPair,
    takeProfitPrice,
    estTot,
    pairData,

    firstCurrency,
  } = formValue;
  const { marketPair, currentPrice } = marketValue;
  const handleClosePairChart = () => setShowPairChart(false);
  const handleShowPairChart = (data) => {
    let chaData = {
      chart: {
        pair: `${data.firstCurrency}-${data.secondCurrency}`,
        exchange: exchange,
        bot: "GENIE",
      },
      pairName: data.pairName,
    };
    setChartData(chaData);
    setShowPairChart(true);
  };

  const handleCloseSellConfirmation = () => setShowSellConfirmation(false);
  const handleShowSellConfirmation = (row, index, status) => {
    setShowSellConfirmation(true);
    setSellData(row);
  };

  const handleCloseNoTarget = () => setShowNoTarget(false);
  const handleShowNoTarget = () => setShowNoTarget(true);

  const handleCloseConfirmOrder = () => setShowConfirmOrder(false);
  const handleShowConfirmOrder = () => {
    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    setShowConfirmOrder(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setInfoDet(data);
    setShow(true);
  };
  const handleChange = async (e) => {
    e.preventDefault();
    setValidateError({});
    const { name, value } = e.target;
    let formData = { ...formValue };
    if (isEmpty(pair)) {
      setValidateError({ pair: "Kindly Select Coin" });
      return;
    }
    let reqData = {
      exchange: exchange,
      pair: pair,
      amount: value,
    };
    if (/[^0-9.]/.test(value)) return;
    let curPrice = await getcurrentmarktprice(reqData);
    if (name == "takeProfitPercentage") {
      let tp = (curPrice.price * value) / 100;

      formData = {
        ...formValue,
        ...{ [name]: value, takeProfitPrice: curPrice.price + tp },
      };
    }
    if (name == "quantity" && value > 0) {
      formData = {
        ...formValue,
        ...{
          [name]: value,
          estTot: toFixedDown(value / curPrice.price, 6),
        },
      };
    } else if (name == "quantity" && value <= 0) {
      formData = {
        ...formValue,
        ...{ [name]: value, estTot: 0 },
      };
    }
    setFormValue(formData);
  };

  const getcurrentmarktprice = async (reqData) => {
    const { status, loading, result, error } = await currentMarketPrice(
      reqData
    );
    if (status == "success") {
      return { price: result.currentPrice };
    }
  };
  const handleSelect = (eventKey, event) => {
    console.log(eventKey, "-------213");
    setSelectedValue(eventKey);
    setFormValue(orderData);
    history(`/trade-signal/${eventKey}`);
  };
  const handleTab = (eventKey) => {
    // let tabRec = eventKey == "buy" ? "home-tab" : "profile-tab";
    setRecentType(eventKey);
    setStartDate();
    setEndDate();
  };
  const hClearSelect = async () => {
    console.log("---------235");
    setsearchbyinput("");
    setPairList(PairListData);
  };
  const handlePairSelect = async (eventKey) => {
    setValidateError({});
    let pairEvent = PairList.find(
      (el) => el._id.toString() == eventKey.toString()
    );
    let reqData = {
      exchange: exchange,
      pair: pairEvent?.tikerRoot,
      amount: 1,
    };
    let curPrice = await getcurrentmarktprice(reqData);
    let formData = {
      ...formValue,
      ...{
        pair: pairEvent?.tikerRoot,
        buyPair: pairEvent?.firstCurrencySymbol,
        pairData: pairEvent,
        firstCurrency: pairEvent.firstCurrencySymbol,
        quantity: "",
        takeProfitPercentage: "",
        takeProfitPrice: "",
        estTot: "",
      },
    };

    let marketfor = {
      ...marketValue,
      ...{ marketPair: pairEvent?.tikerRoot, currentPrice: curPrice.price },
    };
    setMarketValue(marketfor);
    setFormValue(formData);
  };
  useEffect(() => {
    fetchDetails();
    fetchExchange();
    fetchPairs();
  }, [selectedValue]);
  useEffect(() => {
    fetchRecentOrders(recentType);
    socketContext.socket.on("recentTradeSocket", (result) => {
      let pairList = [];
      for (let item of result.data) {
        console.log(result.data, "-----------281");
        if (exchange == item.exchange) {
          if (recentType == "buy") {
            let profitBuy = {
              priceDecimal: item.priceDecimal,
              takerCommission: item.takerCommission,
              filled: item.filled[0],
              firstCurrency: item.firstCurrency,
              secondCurrency: item.secondCurrency,
              orgquantity: item.filled[0].orgquantity,
              price: item.filled[0].price,
              orderVia: item.filled[0].orderVia,
              status: item.status,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            };
            pairList.push(profitBuy);
          }
          if (recentType == "sell" && item.filled.length >= 2) {
            let profitSell = {
              priceDecimal: item.priceDecimal,
              takerCommission: item.takerCommission,
              firstCurrency: item.firstCurrency,
              secondCurrency: item.secondCurrency,
              buyOrgQty: item.filled[0].orgquantity,
              buyPrice: item.filled[0].price,
              buyQty: item.filled[0].quantity,
              comissionFee: item.filled[0].comissionFee,
              sellOrgQty: item.filled[1].orgquantity,
              sellPrice: item.filled[1].price,
              sellQty: item.filled[1].quantity,
              orderVia: item.filled[1].orderVia,
              orgquantity: item.filled[0].orgquantity,
              price: item.filled[0].price,
              status: item.filled[1].status,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              filled: item.filled,
            };
            let pnl = profitRecent(profitSell, "PriceInclude");
            pairList.push({
              ...profitSell,
              pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
              profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
              currentValue: pnl.curVal,
            });
          }
        }
      }
      setRecentOrders(pairList);
    });
  }, [recentType, exchange, startDate, endDate]);
  useEffect(() => {
    fetchOpenOrders();
    let socketTicker = "socketTicker" + exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      setOpenPosition((el) => {
        let pairList = [];
        el &&
          el.map((item) => {
            if (item.pairId == result.pairId) {
              let feeFilled = fFilled(item.filled[0], item.firstCurrency);
              console.log(result, "-------318");
              let pnl = profitOpen(item, {}, result, "markSocket", feeFilled);
              let low = result.data.markPrice; // result.data.low;
              let high = result.data.high;
              let value = low * item.orgquantity;
              let difference = value - item.cost;
              let feeVal = low * item.quantity;
              let difValue = feeVal - feeVal * item.takerCommission;
              if (feeFilled.feeCoin != item.firstCurrency) {
                difValue = difValue - feeFilled.feeUSDT;
              }
              let differentFee;
              if (exchange == "BitMart") {
                differentFee =
                  difValue - item.cost + item.positionFilled[0]?.comissionFee;
              } else {
                differentFee = difValue - item.cost;
              }
              console.log(pnl, "----------323");
              console.log(value, "----------value");
              console.log(item.cost, "----------item");
              pairList.push({
                ...item,
                pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
                profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
                currentValue: pnl.curVal,
                high: high,
                low: low,
                markPrice: result.data.markPrice,
                difference: difference,
                differentFee: differentFee,
                value: feeVal,
              });
            } else {
              pairList.push(item);
            }
          });
        return pairList;
      });

      setInfoDet((item) => {
        let pairList = {};
        if (item.pairId == result.pairId) {
          let feeFilled = fFilled(item.filled[0], item.firstCurrency);
          let pnl = profitOpen(item, {}, result, "markSocket", feeFilled);
          let low = result.data.markPrice; // result.data.low;
          let high = result.data.high;
          let value = low * item.orgquantity;
          let difference = value - item.cost;
          let feeVal = low * item.quantity;
          let difValue = feeVal - feeVal * item.takerCommission;
          if (feeFilled.feeCoin != item.firstCurrency) {
            difValue = difValue - feeFilled.feeUSDT;
          }
          let differentFee = difValue - item.cost;
          pairList = {
            ...item,
            pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
            profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
            high: high,
            low: low,
            markPrice: result.data.markPrice,
            difference: difference,
            differentFee: differentFee,
            value: feeVal,
          };
        } else {
          pairList = item;
        }
        return pairList;
      });
      setMarketValue((item) => {
        let pairList = {};
        let pName = pData(exchange, result.pairName);
        if (item.marketPair == pName) {
          pairList = {
            ...item,
            currentPrice: result.data.markPrice,
          };
        } else {
          pairList = item;
        }
        return pairList;
      });
    });
  }, [exchange]);

  const pData = (exchange, data) => {
    if (exchange == "BitMart" || exchange == "Bybit") {
      let originalData = data.split("-");
      return originalData[0] + originalData[1];
    } else {
      return data;
    }
  };
  useEffect(() => {
    socketContext.socket.on("openPositionSocket", (result) => {
      let pairList = [];
      for (let item of result.data) {
        if (exchange == item.exchange) {
          let feeFilled = fFilled(item.filled[0], item.firstCurrency);
          let pnl = profitOpen(item, {}, {}, "positionSocket", feeFilled);
          let value = item.low * item.orgquantity;
          let difference = value - item.cost;
          let positionFilled = item.positionFilled[0];
          let feeVal = item.low * item.quantity;
          let difValue = feeVal - feeVal * item.takerCommission;
          if (feeFilled.feeCoin != item.firstCurrency) {
            difValue = difValue - feeFilled.feeUSDT;
          }
          let differentFee;
          if (exchange == "BitMart") {
            differentFee = difValue - item.cost + positionFilled?.comissionFee;
          } else {
            differentFee = difValue - item.cost;
          }
          pairList.push({
            ...item,
            pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
            profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
            high: item.high,
            low: item.low,
            markPrice: item.markPrice,
            difference: difference,
            differentFee: differentFee,
            value: feeVal,
            positionFilled: positionFilled,
          });
        }
      }
      setOpenPosition(pairList);
    });
  }, [exchange]);
  const fetchDetails = async () => {
    try {
      let reqData = {
        exchange,
      };
      const { status, result, total, totalLocked } = await setBalanceDataAll(
        reqData
      );
      let sctfilter = result.filter((o) => o.asset === "USDT");
      let balanceinEx = sctfilter[0]?.free;
      console.log(balanceinEx, "----balanceinEx");
      const getlock = await getlockedAmt({
        exchange: exchange,
        bot: "Genie",
      });
      console.log(getlock, "----getlock");

      let lockedAmt = getlock.result.lockedAmt;
      balanceinEx = parseFloat(balanceinEx) - parseFloat(lockedAmt);

      if (status == "success") {
        setTotalUsdtBal(toFixed(balanceinEx, 6));
      }
    } catch (err) {}
  };
  const fetchExchange = async () => {
    try {
      const { status, result } = await getExchangeData();
      if (status == "success") {
        setExchangeData(result);
        setExchangeSearch(result);
      }
    } catch (err) {}
  };
  const fetchRecentOrders = async (type) => {
    try {
      let reqData = {
        type: type,
        exchange: exchange,
        startDate: startDate,
        endDate: endDate,
        recentType: recentType,
      };
      const { status, result, message } = await getRecentOrders(reqData);
      if (status == "success") {
        let pairList = [];
        for (let item of result) {
          if (exchange == item.exchange) {
            if (recentType == "buy") {
              let profitBuy = {
                priceDecimal: item.priceDecimal,
                takerCommission: item.takerCommission,
                filled: item.filled[0],
                firstCurrency: item.firstCurrency,
                secondCurrency: item.secondCurrency,
                orgquantity: item.filled[0].orgquantity,
                price: item.filled[0].price,
                orderVia: item.filled[0].orderVia,
                status: item.status,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
              };
              pairList.push(profitBuy);
            }
            if (recentType == "sell" && item.filled.length >= 2) {
              let profitSell = {
                priceDecimal: item.priceDecimal,
                takerCommission: item.takerCommission,
                firstCurrency: item.firstCurrency,
                secondCurrency: item.secondCurrency,
                buyOrgQty: item.filled[0].orgquantity,
                buyPrice: item.filled[0].price,
                buyQty: item.filled[0].quantity,
                comissionFee: item.filled[0].comissionFee,
                sellOrgQty: item.filled[1].orgquantity,
                sellPrice: item.filled[1].price,
                sellQty: item.filled[1].quantity,
                orderVia: item.filled[1].orderVia,
                orgquantity: item.filled[0].orgquantity,
                price: item.filled[0].price,
                status: item.status,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                filled: item.filled,
              };
              let pnl = profitRecent(profitSell, "PriceInclude");
              pairList.push({
                ...profitSell,
                pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
                profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
                currentValue: pnl.curVal,
              });
            }
          }
        }
        setRecentOrders(pairList);
      } else {
        toastAlert("error", message, "recentError");
      }
    } catch (err) {}
  };

  const profitRecent = (item) => {
    try {
      let feeFilled = fFilled(item.filled[0], item.firstCurrency);
      let priceDecimal = item.priceDecimal;
      let takerCommission = item.takerCommission;
      let finalQty = item.sellQty;
      console.log(
        feeFilled,
        "------feeFilled",
        item.firstCurrency,
        takerCommission
      );
      // if (feeFilled.feeCoin != item.firstCurrency) {
      //   finalQty = item.orgquantity;
      // }
      console.log(finalQty, "------548");
      let exitOrderValue = item.sellPrice * finalQty;

      let feeSF = fFilled(item.filled[1], item.secondCurrency);
      console.log(feeSF, "-------552");
      if (feeSF.feeCoin != item.secondCurrency) {
        exitOrderValue = exitOrderValue - feeSF.feeUSDT;
      } else {
        exitOrderValue = exitOrderValue - exitOrderValue * takerCommission;
      }
      console.log(exitOrderValue, "-------558");
      exitOrderValue = toFixedDown(exitOrderValue, priceDecimal);
      let entryOrderValue = item.buyPrice * item.buyOrgQty;
      if (feeFilled.feeCoin != item.firstCurrency) {
        exitOrderValue = exitOrderValue - feeFilled.feeUSDT;
      }
      console.log(exitOrderValue, "-------exitOrderValue", entryOrderValue);
      let finalProfit;
      if (exchange == "BitMart") {
        finalProfit = exitOrderValue - entryOrderValue + item.comissionFee;
      } else {
        finalProfit = exitOrderValue - entryOrderValue;
      }
      let finalProfitPercentage = (finalProfit / entryOrderValue) * 100;
      let curVal = entryOrderValue + finalProfit;
      return {
        finalProfitPercentage: finalProfitPercentage,
        finalProfit: finalProfit,
        curVal: curVal,
      };
    } catch (err) {
      console.log(err, "-------550");
    }
  };
  const fFilled = (filled, firstCurrency) => {
    try {
      let feeUSDT = 0,
        feeCoin = firstCurrency;
      console.log(filled, "-------537");
      if (!isEmpty(filled?.feeFilled)) {
        for (let data of filled.feeFilled) {
          feeUSDT += data.commissionUSDT;
          feeCoin = data.commissionAsset;
        }
      }
      return { feeUSDT, feeCoin };
    } catch (err) {
      console.log(err, "----3333");

      return 0;
    }
  };
  const fetchOpenOrders = async () => {
    try {
      let reqData = {
        exchange,
        botCategory: "manual",
      };
      const { status, result, pairDetails } = await getManualPosition(reqData);
      if (status == "success") {
        let pairList = [];
        for (let item of result) {
          let feeFilled = fFilled(item.filled[0], item.firstCurrency);
          console.log(feeFilled, "---------559");
          let pnl = profitOpen(item, pairDetails, {}, "api", feeFilled);
          let pairData = pairDetails.find(
            (el) => el._id.toString() == item.pairId.toString()
          );
          let value = pairData.low * item.orgquantity;
          let difference = value - item.cost;
          let positionFilled = item.positionFilled[0];
          let feeVal = pairData.low * item.quantity;
          let difValue = feeVal - feeVal * item.takerCommission;
          if (feeFilled.feeCoin != item.firstCurrency) {
            difValue = difValue - feeFilled.feeUSDT;
          }
          let differentFee;
          if (exchange == "BitMart") {
            differentFee = difValue - item.cost + positionFilled?.comissionFee;
          } else {
            differentFee = difValue - item.cost;
          }
          pairList.push({
            ...item,
            pl: pnl.finalProfitPercentage ? pnl.finalProfitPercentage : 0.0,
            profit: pnl.finalProfit ? pnl.finalProfit : 0.0,
            markPrice: pairData.markPrice,
            high: pairData.high,
            low: pairData.low,
            difference: difference,
            differentFee: differentFee,
            value: feeVal,
            positionFilled: positionFilled,
          });
        }

        setOpenPosition(pairList);
      }
    } catch (err) {}
  };
  const profitOpen = (item, pairDetails, result, type, feeFilled) => {
    try {
      let priceDecimal = item.priceDecimal;
      let finalQty = calPrice(item.filled, "quantity");
      console.log(feeFilled, "-------615", item.firstCurrency);
      if (feeFilled.feeCoin != item.firstCurrency) {
        finalQty = calPrice(item.filled, "feeQty");
      }
      console.log(finalQty, "---------621");
      let entryOrderValue = calPrice(item.filled, "Totalprice");
      // entryOrderValue = toFixedDown(entryOrderValue, priceDecimal);
      let exitOrderValue = 0;
      if (type === "api") {
        let pairData = pairDetails.find(
          (el) => el._id.toString() == item.pairId.toString()
        );
        exitOrderValue = pairData.markPrice * finalQty;
      } else if (type === "markSocket") {
        exitOrderValue = result.data.markPrice * finalQty; // result.data.low * finalQty; // calculate lowprice base
      } else if (type === "positionSocket") {
        exitOrderValue = item.markPrice * finalQty;
      }
      exitOrderValue = exitOrderValue - exitOrderValue * item.takerCommission;
      console.log(exitOrderValue, "---------635", item.takerCommission);
      if (feeFilled.feeCoin != item.firstCurrency) {
        exitOrderValue = exitOrderValue - feeFilled.feeUSDT;
      }
      console.log(exitOrderValue, "-------636", entryOrderValue);
      // exitOrderValue = toFixedDown(exitOrderValue, priceDecimal);
      let finalProfit = exitOrderValue - entryOrderValue;
      let profitPercentage = (finalProfit / entryOrderValue) * 100;
      return {
        finalProfitPercentage: profitPercentage,
        finalProfit: finalProfit,
      };
    } catch (err) {}
  };
  const fetchPairs = async (currency) => {
    const { result } = await getTradeManualPair({
      exchange: params.exchange,
      currency: secondCurrency,
    });
    setPairListData(result);
    setPairList(result);
  };

  const searchpair = (e) => {
    e.preventDefault();
    setsearchbyinput(e.target.value);
    var searcharr = [];
    if (PairListData && PairListData.length > 0) {
      for (var i = 0; i < PairListData.length; i++) {
        var temppair =
          PairListData[i].firstCurrencySymbol +
          "/" +
          PairListData[i].secondCurrencySymbol;
        if (temppair.indexOf(e.target.value.toUpperCase()) !== -1) {
          searcharr.push(PairListData[i]);
        }
        if (i == PairListData.length - 1) {
          setPairList(searcharr);
        }
      }
    }
  };
  const handleOrderPlace = async (orderType) => {
    setLoader(true);
    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    let reqData = {
      exchange: exchange,
      pair: pair,
      amount: quantity,
      // takeprofit: takeProfitPercentage,
      // takeProfitPrice: takeProfitPrice,
      side: orderType,
      estTot: estTot,
      pairData: pairData,
    };
    const { status, message, error } = await manualOrderPlace(reqData);
    setLoader(false);
    if (status == "success") {
      toastAlert("success", message, "trademanualorderplace");
      setFormValue(orderData);
      setValidateError({});
      fetchDetails();
      setShowConfirmOrder(false);
      setRecentType("buy");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "trademanualorderplace");
    }
  };

  const handleDownload = async () => {
    let csvData = [];
    if (recentType == "buy") {
      csvData = [
        ["Coin", "Total", "Trigger", "Time", "Status", "Created Date"],
      ];
    }
    if (recentType == "sell") {
      csvData = [
        [
          "Coin",
          "Total",
          "PNL(%)",
          "PNL",
          "Current Value",
          "Trigger",
          "Time",
          "Closed Date",
        ],
      ];
    }
    for (let item of recentOrders) {
      if (recentType == "buy") {
        let arrData = [
          item.firstCurrency,
          toFixedDown(item.orgquantity * item.price, item.priceDecimal),
          item.orderVia,
          item.status == "open"
            ? dateHours(item.createdAt)
            : dateHoursclose(item.createdAt, item.updatedAt),
          item.status,
          dateTimeFormat(item.createdAt),
        ];
        csvData.push(arrData);
      }
      if (recentType == "sell") {
        let arrData = [
          item.firstCurrency,
          toFixedDown(item.orgquantity * item.price, item.priceDecimal),
          toFixed(item.pl, 2),
          `${toFixed(item.profit, item.priceDecimal)} ${item.secondCurrency}`,
          toFixedDown(item.currentValue, item.priceDecimal),
          item.orderVia,
          dateHoursclose(item.createdAt, item.updatedAt),
          dateTimeFormat(item.updatedAt),
        ];
        csvData.push(arrData);
      }
    }

    // Convert the data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "RecentTrade.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };
  const columnRecentSell = [
    {
      name: "Coin",
      selector: (row) => row.firstCurrency,
    },
    {
      name: "Total",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixedDown(row.orgquantity * row.price, row.priceDecimal)}{" "}
            <span className="greyTxt">{row.secondCurrency}</span>
          </p>
        );
      },
    },
    {
      name: "PNL",
      selector: (row) => {
        let txtClr = row && row.pl < 0 ? "redText" : "greenTxt";
        return (
          <div>
            <p className={`${txtClr} mb-1`}>{toFixed(row.pl, 2)} %</p>
            <p className="mb-0">
              {toFixed(row.profit, row.priceDecimal)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
    },

    {
      name: "Current Value",
      selector: (row) => {
        let txtClr = row && row.pl < 0 ? "redText" : "greenTxt";
        return (
          <p className="mb-0">
            {toFixedDown(row.currentValue, row.priceDecimal)}{" "}
            <span className="greyTxt">{row.secondCurrency}</span>
          </p>
        );
      },
    },
    {
      name: "Trigger",
      selector: (row) => row.orderVia,
    },
    {
      name: "Time",
      selector: (row) => dateHoursclose(row.createdAt, row.updatedAt),
      // width: "175px",
    },
    {
      name: "Closed Date",
      selector: (row) => dateTimeFormat(row.updatedAt),
      width: "175px",
    },
    // {
    //   name: "View",
    //   selector: (row) => row.View,
    // },
  ];
  const columnRecentBuy = [
    {
      name: "Coin",
      selector: (row) => row.firstCurrency,
    },
    {
      name: "Total",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixedDown(row.orgquantity * row.price, row.priceDecimal)}{" "}
            <span className="greyTxt">{row.secondCurrency}</span>
          </p>
        );
      },
    },
    {
      name: "Trigger",
      selector: (row) => {
        return row.orderVia;
      },
    },
    {
      name: "Time",
      selector: (row) =>
        row.status == "open"
          ? dateHours(row.createdAt)
          : dateHoursclose(row.createdAt, row.updatedAt),
      // width: "175px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "175px",
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "175px",
    },
    // {
    //   name: "View",
    //   selector: (row) => row.View,
    // },
  ];
  const columnsOpenPosition = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "6%",
    },
    {
      name: (
        <label class="form-check-label" for="cryptoCoin1">
          <input
            class="form-check-input-tm"
            type="checkbox"
            id="cryptoCoin1"
            checked={allCheck}
            onClick={(e) => handleAllCheck(e)}
          />
        </label>
      ),
      selector: (row) => {
        let checkData = viewData.find((el) => el == row._id);
        return (
          <label class="form-check-label" for="cryptoCoin1">
            <input
              class="form-check-input-tm"
              type="checkbox"
              value={row._id}
              name={row._id}
              checked={isEmpty(checkData) ? false : true}
              onChange={(e) => handleCheck(e)}
              id="cryptoCoin1"
            />
          </label>
        );
      },
      width: "8%",
    },
    {
      name: "Coin",
      selector: (row) => {
        return (
          <span
            className="currenct-name ms-0 orange_link"
            onClick={() => handleShowPairChart(row)}
          >
            {row.firstCurrency}
          </span>
        );
      },
      width: "12%",
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixed(row.orgquantity, row.quantityDecimal)}{" "}
            <span className="greyTxt">{row.firstCurrency}</span>
          </p>
        );
      },
      width: "12%",
    },
    {
      name: "Cost",
      selector: (row) => {
        return (
          <div>
            <p className="mb-0">
              {toFixedDown(row.cost, row.priceDecimal)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
      width: "15%",
    },
    {
      name: "PNL",
      selector: (row) => {
        // let txtClr = row && row.pl < 0 ? "redText" : "greenTxt";
        // return <p className={`${txtClr} mb-0`}>{toFixed(row.pl, 2)}</p>;

        let txtClr = row && row.pl < 0 ? "redText" : "greenTxt";
        return (
          <div>
            <p className={`${txtClr} mb-0`}>{toFixed(row.pl, 2)} %</p>
            <p className="mb-0">
              {toFixed(row.profit, row.priceDecimal)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
      width: "16%",
    },
    {
      name: "Age",
      selector: (row) => dateHours(row.createdAt),
      width: "10%",
    },
    {
      name: "Action",
      selector: (row, index) => {
        let loaderCond = sellLoader && index == loaderIndex;
        return (
          <div>
            {/* <button type="button" class="btn btn-primary btn-sm mx-1">
              <i class="bi bi-unlock"></i>
            </button> */}
            <button
              type="button"
              // onClick={() => handleSell(row, index, true)}
              onClick={() => handleShowSellConfirmation(row, index, true)}
              class="btn btn-primary btn-sm mx-1"
              disabled={loaderCond}
            >
              {loaderCond && <div class="loader"></div>}
              Sell
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm mx-1 info_btn"
              onClick={() => handleShow(row)}
            >
              <i class="bi bi-info-circle me-2"></i>
              Info
            </button>
            {/* <button type="button" class="btn btn-primary btn-sm mx-1"><i class="bi bi-share-fill"></i></button> */}
          </div>
        );
      },
      width: "20%",
    },
  ];

  //function
  const handleCheck = (e) => {
    try {
      const { name } = e.target;
      let newData = viewData;
      let condition = newData.find((el) => el == name);
      if (!isEmpty(condition)) {
        let newData = [...viewData];
        newData.splice(newData.indexOf(condition), 1);
        setAllCheck(false);
        setViewData(newData);
      } else {
        newData = [...viewData];
        newData.push(e.target.name);
        setViewData(newData);
      }
    } catch (err) {}
  };

  const handleAllCheck = (e) => {
    try {
      const { checked } = e.target;
      setAllCheck(checked);
      let checkArr = openPosition;
      if (checked) {
        let newData = [];
        checkArr &&
          checkArr.map((item, i) => {
            let pairName = item._id;
            newData.push(pairName);
          });
        setViewData(newData);
      } else {
        setViewData([]);
      }
    } catch (err) {}
  };
  const handleSell = async (data, index, showStatus) => {
    loaderSet(true, index, showStatus);
    let reqData = {
      exchange: exchange,
      pair: data.pairName,
      amount: data.initialAmount,
      // takeprofit: data.takeProfit,
      // takeProfitPrice: data.takeProfitPrice,
      estTot: data.estimatedTot,
      side: "sell",
      orderId: data._id,
      // pairData: pairData,
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      loaderSet(false, index, showStatus);
      return;
    }

    const { status, message, error } = await manualOrderPlace(reqData);
    loaderSet(false, index, showStatus);
    if (status == "success") {
      toastAlert("success", message, "trademanualorderplace");
      setFormValue(orderData);
      setValidateError({});
      fetchDetails();
      setRecentType("sell");
      setShowSellConfirmation(false);
      if (!showStatus) {
        setInfoDet({});
        setShow(false);
      }
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "trademanualorderplace");
    }
  };

  const loaderSet = (condition, index, showStatus) => {
    let formData = {
      ...loaderForm,
      ...{ sellLoader: condition, loaderIndex: index },
    };
    if (!showStatus) {
      formData = {
        ...loaderForm,
        ...{ positionLoader: condition },
      };
    }
    setLoaderForm(formData);
  };
  const onSplit = async () => {
    let reqData = {
      orderId: [infoDet._id],
      action: "split",
    };
    const { status, message } = await bulkEdit(reqData);
    if (status == "success") {
      setViewData([]);
      setShow(false);
      toastAlert("success", message, "bulkClick");
    } else {
      toastAlert("error", message, "bulkClick");
    }
  };
  const onBulkClick = async (action) => {
    if (viewData.length <= 0) {
      toastAlert("error", "Select Coin", "bulkClick");
      return;
    }
    if (viewData.length >= 2 && action == "split") {
      toastAlert("error", "Select Only One Coin", "bulkClick");
      return;
    }
    if (viewData.length <= 1 && action == "merge") {
      toastAlert("error", "Select More than Two Coins", "bulkClick");
      return;
    }
    let reqData = {
      orderId: viewData,
      action: action,
    };
    const { status, message } = await bulkEdit(reqData);
    if (status == "success") {
      setViewData([]);
      setAllCheck(false);
      toastAlert("success", message, "bulkClick");
    } else {
      toastAlert("error", message, "bulkClick");
    }
  };
  const searchExc = (e) => {
    e.preventDefault();
    setSBExc(e.target.value);
    var searcharr = [];
    if (exchangeSearch && exchangeSearch.length > 0) {
      for (var i = 0; i < exchangeSearch.length; i++) {
        var temppair = exchangeSearch[i].exchange.toLowerCase();
        if (temppair.indexOf(e.target.value.toLowerCase()) !== -1) {
          searcharr.push(exchangeSearch[i]);
        }
        if (i == exchangeSearch.length - 1) {
          setExchangeData(searcharr);
        }
      }
    }
  };
  // Function to filter dates after today
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    return date <= currentDate;
  };
  const isFutureDate = (date) => {
    const currentDate = new Date();
    // Check if the date is in the future
    return date > currentDate;
  };

  useEffect(() => {
    // console.log(botId, "------143");
    socketContext.socket.on("BalanceSocketAll", async (Socresult) => {
      if (exchange == Socresult.exchange) {
        if (Socresult.message == "Success") {
          let result = Socresult.result;

          if (result.length > 0) {
            let sctfilter = result.filter((o) => o.asset === "USDT");
            let balanceinEx = sctfilter[0]?.free;
            console.log(balanceinEx, "----balanceinEx");
            const getlock = await getlockedAmt({
              exchange: exchange,
              bot: "Genie",
            });
            console.log(getlock, "----getlock");

            let lockedAmt = getlock.result.lockedAmt;
            balanceinEx = parseFloat(balanceinEx) - parseFloat(lockedAmt);
            setTotalUsdtBal(toFixed(balanceinEx, 6));
          }
        }
      }
    });

    return () => {
      socketContext.socket.off("BalanceSocketAll");
    };
  }, [exchange]);

  useEffect(() => {
    socketContext.socket.on("openGridSocket", (result) => {
      console.log(exchange, "---------1252", result);
      if (exchange == result.exchange) {
        fetchDetails();
      }
    });
  }, [exchange]);
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content mt-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="card trade_card trade_history_card">
                <div className="card-header">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Open Positions
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Reserved Funds
                      </button>
                    </li> */}
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade active show"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="action-filter">
                        <div className="af-left">
                          <p>These are your current open positions.</p>
                        </div>
                        <div className="af-right">
                          {/* <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <input
                              class="form-check-input-tm"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            Market orders
                          </label> */}
                          {openPosition && openPosition.length > 0 && (
                            <div class="dropdown">
                              <button
                                class="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Bulk actions
                              </button>
                              <ul
                                class="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li onClick={() => onBulkClick("split")}>
                                  <a class="dropdown-item" href="#">
                                    Split Option
                                  </a>
                                </li>
                                <li onClick={() => onBulkClick("merge")}>
                                  <a class="dropdown-item" href="#">
                                    Merge Option
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="primary_table">
                        <DataTable
                          columns={columnsOpenPosition}
                          data={openPosition}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="primary_table">table 2</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card trade_card trade_history_card mt-4">
                <div className="card-header align-items-end flex-wrap gap-2">
                  <ul className="nav nav-tabs" id="myTab1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          recentType == "sell" ? "nav-link active" : "nav-link"
                        }
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#recentsells"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => handleTab("sell")}
                      >
                        Recent Sells
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          recentType == "buy" ? "nav-link active" : "nav-link"
                        }
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#recentbuys"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => handleTab("buy")}
                      >
                        Recent Buys
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="filter_flx p-3 signal_bot_filter_flx">
                    <div>
                      {/* <span className="d-block mb-1">Start Date:</span> */}
                      <DatePicker
                        selected={startDate}
                        // showTimeSelect
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Start Date"
                        filterDate={filterPassedDate} // Apply the filter function
                        isDisabled={isFutureDate} // Disable future dates
                      />
                    </div>
                    <div>
                      {/* <span className="d-block mb-1">End Date:</span> */}
                      <DatePicker
                        selected={endDate}
                        // showTimeSelect
                        onChange={(date) => setEndDate(date)}
                        placeholderText="End Date"
                        filterDate={filterPassedDate} // Apply the filter function
                        isDisabled={isFutureDate} // Disable future dates
                      />
                    </div>
                    <button
                      className="continue w-auto"
                      onClick={handleDownload}
                    >
                      Download CSV
                    </button>
                  </div>
                  <div className="tab-content" id="myTabContent1">
                    <div
                      className={
                        recentType == "sell"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="recentsells"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="primary_table">
                        <DataTable
                          columns={columnRecentSell}
                          pagination
                          data={recentOrders}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        recentType == "buy"
                          ? "tab-pane fade active show"
                          : "tab-pane fade"
                      }
                      id="recentbuys"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="primary_table">
                        <DataTable
                          columns={columnRecentBuy}
                          pagination
                          data={recentOrders}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="trade_main_settings">
                <div className="card trade_card mb-1">
                  <div className="card-header">
                    <h2>Available Balance</h2>
                  </div>
                  <div className="card-body p-3">
                    <div className="drpdwn_box">
                      <Dropdown onSelect={handleSelect} className="w-100">
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="exchange">Exchange</span>
                          {/* <img
                            width="10%"
                            src={
                              exchange == "Binance"
                                ? binance
                                : exchange == "Kucoin"
                                ? kucoin
                                : exchange == "BitMart"
                                ? bitmart
                                : ""
                            }
                            class="img-fluid crypto me-2"
                            alt="icon"
                          /> */}
                          {selectedValue}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="search_inp_div">
                            <input
                              type="text"
                              placeholder="Search Exchange"
                              className="form-control search_inp"
                              name="symbol"
                              value={sbExc}
                              onChange={searchExc}
                            />
                            <i className="bi bi-search"></i>
                          </div>
                          {exchangeData &&
                            exchangeData.length > 0 &&
                            exchangeData.map((item, index) => {
                              let imgExchange =
                                item.exchange == "Binance"
                                  ? binance
                                  : item.exchange == "Bybit"
                                  ? bybit
                                  : bitmart;
                              return (
                                <Dropdown.Item eventKey={item.exchange}>
                                  <img
                                    src={imgExchange}
                                    class="img-fluid crypto me-2"
                                    alt="icon"
                                  />
                                  {item.exchange}
                                </Dropdown.Item>
                              );
                            })}
                          {/* <Dropdown.Item eventKey="Binance">
                            <img
                              src={binance}
                              class="img-fluid crypto me-2"
                              alt="icon"
                            />
                            Binance
                          </Dropdown.Item>

                          <Dropdown.Item eventKey="Kucoin">
                            <img
                              src={kucoin}
                              class="img-fluid crypto me-2"
                              alt="icon"
                            />
                            Kucoin
                          </Dropdown.Item>

                          <Dropdown.Item eventKey="BitMart">
                            <img
                              src={bitmart}
                              class="img-fluid crypto me-2"
                              alt="icon"
                            />
                            BitMart
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="balance-trade-manual">
                      <>
                        <h5>
                          Total <span>USDT</span> in assets on exchange:{" "}
                        </h5>

                        <h2>{TotalUsdtBal}</h2>
                      </>

                      {/* {balanceData &&
                        balanceData.byBitStatus &&
                        exchange == "Bybit" &&
                        balanceData.bybit &&
                        balanceData.bybit.length > 0 &&
                        balanceData.bybit.map((item, i) => {
                          if (item.coin == "USDT") {
                            return (
                              <>
                                <h5>
                                  Total <span>USDT</span> in assets on exchange:{" "}
                                </h5>
                                <h2>{item.walletBalance}</h2>
                              </>
                            );
                          }
                        })}
                      {balanceData &&
                        balanceData.bitmartStatus &&
                        exchange == "BitMart" &&
                        balanceData.bitmart &&
                        balanceData.bitmart.length > 0 &&
                        balanceData.bitmart.map((item, i) => {
                          if (item.currency == "USDT") {
                            return (
                              <>
                                <h5>
                                  Total <span>USDT</span> in assets on exchange:{" "}
                                </h5>
                                <h2>{item.available}</h2>
                              </>
                            );
                          }
                        })} */}

                      {/* <h2>
                        45,371.50 <span className="greenTxt">(2.59%)</span>
                      </h2>
                      <h6>
                        <span>Start Balance:</span> 44,222.23{" "}
                        <span>
                          <a href="#">edit</a>
                        </span>
                      </h6> */}
                    </div>
                    {isEmpty(balanceData) && (
                      <h5 className="redText">Please Bind Your API Key</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="trade_main_settings mt-place-order mt-4">
                <div className="card trade_card mb-1">
                  <div className="card-header">
                    <h2>Manual Buy Order</h2>
                  </div>
                  <div className="card-body p-3">
                    <p className="text-label">Place manual market buy orders</p>
                    {/* <div className="flex-text border-bottom">
                      <label className="label-txt">You have:</label>
                      <p className="price-number mb-0">
                        7125.36458 <span>USDT</span>
                      </p>
                    </div> */}
                    <div className="flex-text">
                      <label className="label-txt">Select Pair:</label>
                      {/* <p className="price-number mb-0">
                        <a href="#">
                          <small>Show allowed only</small>
                        </a>
                      </p> */}
                    </div>
                    <div className="drpdwn_box">
                      <Dropdown
                        onSelect={handlePairSelect}
                        className="drp_dn w-100"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <div
                            className="pair_dropdown cursor-pointer"
                            // onClick={() => Onclicksearch()}
                            onClick={() => hClearSelect()}
                          >
                            <p className="mb-0">
                              {!isEmpty(pair) ? pair : "Select Pair"}
                            </p>
                          </div>
                          {validateError && validateError.pair && (
                            <p className="text-danger">{validateError.pair}</p>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="search_inp_div scroll_drp">
                            <div className="make_drpdwn">
                              <input
                                type="text"
                                placeholder="Search Pair"
                                className="form-control search_inp"
                                name="symbol"
                                autocomplete="off"
                                value={searchbyinput}
                                onChange={searchpair}
                              />
                              <i className="bi bi-search"></i>
                            </div>
                            <ul className="mt-coin-list">
                              {PairList &&
                                PairList.length > 0 &&
                                PairList.map((item, key) => {
                                  let tPair = `${item.firstCurrencySymbol}/${item.secondCurrencySymbol}`;
                                  return (
                                    <Dropdown.Item
                                      className="text-white bg-transparent"
                                      eventKey={item._id}
                                    >
                                      {tPair}
                                    </Dropdown.Item>
                                  );
                                })}
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="market-type">
                      <div className="tab">
                        {/* <Form.Check
                        checked={lOrder}
                          inline
                          label="Limit"
                          name="group1"
                          type={"radio"}
                          id={`inline-radio-1`}
                        /> */}
                        <Form.Check
                          checked={mOrder}
                          inline
                          label="Market"
                          name="group1"
                          type={"radio"}
                          id={`inline-checkbox-2`}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Total</label>
                      <div class="input-group">
                        <input
                          type="text"
                          value={quantity}
                          onChange={(e) => handleChange(e)}
                          name="quantity"
                          autocomplete="off"
                          class="form-control dark_bdr"
                        />
                        <span className="input-group-text">
                          {secondCurrency}
                        </span>
                      </div>

                      {validateError && validateError.quantity && (
                        <p className="text-danger">{validateError.quantity}</p>
                      )}
                    </div>
                    {/* <div className="manual-buy-sell border-bottom mb-3">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Buy/Sell Options
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="form-group">
                                <label>Take profit at,%</label>
                                <input
                                  type="number"
                                  value={takeProfitPercentage}
                                  onChange={(e) => handleChange(e)}
                                  name="takeProfitPercentage"
                                  className="form-control"
                                />
                              </div>

                              {validateError &&
                                validateError.takeProfitPercentage && (
                                  <p className="text-danger">
                                    {validateError.takeProfitPercentage}
                                  </p>
                                )}
                              <div className="form-group">
                                <label>Sell price</label>
                                <div class="input-group">
                                  <input
                                    value={takeProfitPrice}
                                    disable={true}
                                    name="takeProfitPrice"
                                    type="text"
                                    class="form-control"
                                  />
                                  <span className="input-group-text">
                                    {secondCurrency}
                                  </span>
                                </div>
                              </div>
                                <div className="form-group">
                                <label>Trailing buy</label>
                                <div className="chk_box">
                                  <div class="form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexSwitchCheckDefault"
                                    ></label>
                                  </div>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <label>Net Amount</label>
                      <div class="input-group">
                        <input
                          type="text"
                          value={estTot}
                          disable={true}
                          name="estTot"
                          class="form-control dark_bdr"
                        />
                        <span className="input-group-text">
                          {firstCurrency}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary d-block w-100 py-2"
                        // onClick={() => handleOrderPlace("buy")}
                        // onClick={handleShowNoTarget}
                        onClick={handleShowConfirmOrder}
                      >
                        Place Buy Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="modal fade primary_modal primary_modal_medium"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">Info</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-body p-0">
            <div className="info-modal-title">
              <h2>
                {infoDet.firstCurrency}{" "}
                {infoDet?.pl > 0 && (
                  <span className="greenTxt">
                    <strong>
                      <i class="bi bi-arrow-up-short"></i>
                    </strong>{" "}
                    {toFixed(infoDet.pl, 2)}%
                  </span>
                )}
                {infoDet?.pl < 0 && (
                  <span className="redText">
                    <strong>
                      <i class="bi bi-arrow-down-short"></i>
                    </strong>{" "}
                    {toFixed(infoDet.pl, 2)}%
                  </span>
                )}
              </h2>
              {/* <h6>
                <span>Open Position:</span> 134628747{" "}
                <a href="#">
                  <i class="bi bi-arrow-clockwise"></i>
                </a>
              </h6> */}
            </div>
            <div className="flex-ul">
              <ul>
                {/* <li>
                  <label>Last:</label>
                  <span className="greenTxt">
                    {toFixedDown(infoDet.markPrice, infoDet.priceDecimal)}
                  </span>
                </li> */}
                {/* <li>
                  <label>Highest Bid:</label>
                  <span className="greenTxt">
                    {toFixedDown(infoDet.high, infoDet.priceDecimal)}
                  </span>
                </li> */}
                <li>
                  <label>Lowest Ask:</label>
                  <span className="greenTxt">
                    {toFixedDown(infoDet.low, infoDet.priceDecimal)}
                  </span>
                </li>
                <li>
                  <label>Buy Rate:</label>
                  <span className="greenTxt">
                    {toFixedDown(infoDet.price, infoDet.priceDecimal)}
                  </span>
                </li>
                <li>
                  <label>Amount:</label>
                  <span>
                    {toFixed(infoDet.orgquantity, infoDet.quantityDecimal)}{" "}
                    {infoDet.firstCurrency}
                  </span>
                </li>
                <li>
                  <label>Cost:</label>
                  <span>{`${toFixedDown(infoDet.cost, infoDet.priceDecimal)} ${
                    infoDet.secondCurrency
                  }`}</span>
                </li>
                <li>
                  <label>Value:</label>
                  <span>{`${toFixedDown(infoDet.value, infoDet.priceDecimal)} ${
                    infoDet.secondCurrency
                  }`}</span>
                </li>
                <li>
                  <label>Difference:</label>
                  <span
                    className={`${
                      infoDet.difference > 0 ? "greenTxt" : "redText"
                    }`}
                  >
                    {toFixed(infoDet.difference, infoDet.priceDecimal)}{" "}
                    {infoDet.secondCurrency}
                  </span>
                </li>

                <li>
                  <label>Market:</label>
                  <span>{`${infoDet.firstCurrency}/${infoDet.secondCurrency}`}</span>
                </li>
                <li>
                  <label>Difference(WithFee):</label>
                  <span
                    className={`${
                      infoDet.differentFee > 0 ? "greenTxt" : "redText"
                    }`}
                  >
                    {toFixed(infoDet.differentFee, infoDet.priceDecimal)}{" "}
                    {infoDet.secondCurrency}
                  </span>
                </li>
                <li>
                  <label>Order Date:</label>
                  <span>{dateTimeFormat(infoDet.createdAt)}</span>
                </li>
                <li>
                  <label>Age:</label>
                  <span>{dateHours(infoDet.createdAt)}</span>
                </li>
                <li>
                  <label>Trigger:</label>
                  <span>{infoDet?.positionFilled?.orderVia}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-button mt-3">
            <button
              className="btn btn-primary"
              // onClick={() => handleSell(infoDet, 0, false)}
              onClick={() => handleShowSellConfirmation(infoDet, 0, false)}
            >
              <i class="bi bi-upload"></i> Sell Position
            </button>
            <div className="btn-right-group">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li onClick={() => onSplit()}>
                    <a class="dropdown-item" href="#">
                      Split Option
                    </a>
                  </li>
                  {/* <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPairChart}
        onHide={handleClosePairChart}
        centered
        size="lg"
        className="modal fade primary_modal primary_modal_large"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">{chartData.pairName}</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-body p-0">
            {/* <img
              src={require("../../assets/images/trade-chart.png")}
              className="img-fluid"
              alt=""
            /> */}
            <Chart
              params={chartData.chart}
              data={intitalChartdata}
              className="img-fluid"
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSellConfirmation}
        onHide={handleCloseSellConfirmation}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="big-center-icon text-center">
              <i class="bi bi-exclamation-circle"></i>
            </div>
            <div className="warning-modal-text text-center">
              <h3>Are you sure?</h3>
              <p>Are you sure you want to sell this position?</p>
              <div className="flex-center-button">
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => handleSell(sellData, 0, false)}
                  disabled={positionLoader}
                >
                  {positionLoader && <div class="loader"></div>}
                  Yes, Sell position
                </button>
                <button
                  className="btn btn-secondary mx-2"
                  onClick={handleCloseSellConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showNoTarget}
        onHide={handleCloseNoTarget}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="big-center-icon text-center">
              <i class="bi bi-x-circle"></i>
            </div>
            <div className="warning-modal-text text-center">
              <h3>No target selected</h3>
              <p>
                You did not select a currency to buy. Please select a currency
                first.
              </p>
              <div className="flex-center-button">
                <button
                  className="btn btn-secondary mx-2 px-4"
                  onClick={handleCloseNoTarget}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmOrder}
        onHide={handleCloseConfirmOrder}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">Confirm buy order</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-body p-0">
            <div className="flex-ul">
              <ul>
                <li>
                  <label>Side:</label>
                  <span className="greenTxt">Buy</span>
                </li>
                <li>
                  <label>Order type:</label>
                  <span className="batchText">
                    {mOrder ? "Market" : "Limit"}
                  </span>
                </li>
                <li>
                  <label>Coin:</label>
                  <span>{firstCurrency}</span>
                </li>
                <li>
                  <label>Price:</label>
                  <span>
                    {currentPrice} {secondCurrency}
                  </span>
                </li>
                <li>
                  <label>Amount:</label>
                  <span>
                    {" "}
                    {toFixedDown(quantity / currentPrice, 4)} {firstCurrency}
                  </span>
                </li>
                <li>
                  <label>Total:</label>
                  <span>
                    {toFixedDown(quantity, 4)} {secondCurrency}
                  </span>
                </li>
              </ul>
              <div className="flex-center-button">
                <button
                  className="btn btn-secondary mx-2"
                  onClick={handleCloseConfirmOrder}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => handleOrderPlace("buy")}
                  disabled={loader}
                >
                  {loader && <div class="loader"></div>}Confirm Order
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
}
