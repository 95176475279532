import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import lib
import config from "../../config";
import { widget } from "../charting_library";

import isEmpty from "../../lib/isEmpty";
import "../chart_css/index.css";
import { momentFormat } from "../../lib/dateFilter";

const chartUrl = config.API_URL;
//const chartUrl = "https://stagingapi.millionero.com";
let tvWidget = null;

const GridChart = (props) => {
  console.log("Gridchart-----");
  const chartContainerRef = useRef();

  // props
  const { params, markPrice } = props;
  console.log(props.datafeedUrl, "---------23", props);
  const tpair = params.pair;
  const exchange = params.exchange;

  let gridLevels = useRef([]),
    lowPriceLine = useRef(null),
    highPriceLine = useRef(null),
    breakingEvent = useRef(null),
    stopLoss = useRef(null),
    flagsLine = useRef([]),
    slOrderLine = useRef(null),
    dcaOrderLine = useRef(null);

  const tradePair = tpair.replace(/-/g, "");
  // state
  const themeData = useSelector((state) => state.tradeThemeReducers);
  const curBot = useSelector((state) => state.curGridBot);
  const botData = useSelector((state) => state.botStatus);

  const [theme, setTheme] = useState(themeData == "dark" ? "White" : "Dark");

  // function
  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair, chartType) => {
    if (!isEmpty(chartType)) {
      chartType = parseInt(chartType);
    } else {
      chartType = 2;
    }
    console.log(pair, "------pair");
    const widgetOptions = {
      symbol: pair,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        // "https://producationapi.mudra.exchange/api/chart"
        props.datafeedUrl + "/" + exchange
      ),
      interval: props.interval,
      container: chartContainerRef.current,
      container_id: props.containerId,
      library_path: props.libraryPath,
      timezone: "Asia/Kolkata",

      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "apply-overflow-tooltip",
        "header_compare",
        "study_templates",
        "header_undo_redo",
        "display_legend_on_all_charts",
        //"create_volume_indicator_by_default",
        "legend_context_menu",
        "format_button_in_legend",
        "source_selection_markers",
        // "volume_force_overlay",
        "hide_last_na_study_output",
        "header_screenshot",
        "border_around_the_chart",
        "display_market_status",
        "header_symbol_search",
      ],
      enabled_features: ["header_settings", "border_around_the_chart"],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      loading_screen: { backgroundColor: "#2c273d" },
      theme: theme,
      style: props.style,
      indicators: props.indicators,
      has_no_volume: props.has_no_volume,
      time_frames: props.time_frames,
      //toolbar_bg: "#1d2146",

      toolbar_bg: "#2c273d",

      overrides: {
        "paneProperties.backgroundType": "#231f30",
        "paneProperties.background": "#231f30",
        "paneProperties.vertGridProperties.color": "#363c4e",
        "paneProperties.horzGridProperties.color": "#363c4e",
        "mainSeriesProperties.style": chartType,
        "scalesProperties.lineColor": "#2c273d",
        "mainSeriesProperties.lineStyle.linewidth": 3,
      },
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      let decimalvalue;
      if (!isEmpty(chartType)) {
        tvWidget.activeChart().setChartType(parseInt(chartType));
      } else {
        tvWidget.activeChart().setChartType(parseInt(1));
      }

      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );
      });
      tvWidget
        .chart()
        .onIntervalChanged()
        .subscribe(null, function (interval, obj) {
          flagsLine.current.forEach((line) =>
            tvWidget.chart().removeEntity(line)
          );
          tvWidget.activeChart().removeEntity();
        });
    });
  };

  useEffect(() => {
    console.log(curBot, "--------curBot", botData);
    if (!isEmpty(curBot) && !isEmpty(curBot.pairName)) {
      let symbol = curBot.pairName;

      let themeValue = "White";
      if (themeData == "light") {
        themeValue = "Dark";
      } else if (themeData == "dark") {
        themeValue = "White";
      }
      buildchart(
        themeValue,
        symbol,
        localStorage.getItem("tradingview.chart.lastUsedStyle")
      );
    }
  }, [themeData, curBot.pairName, exchange]);

  // Process Grid Level Line
  useEffect(() => {
    console.log(botData, "--------botData");
    console.log(curBot, "--------196");
    if (curBot && botData?.openStatus) {
      console.log("--------198", curBot);
      let activeCnt = curBot.actives ? curBot.actives.length : 0;
      let filledCnt = curBot.filled ? curBot.filled.length : 0;
      console.log(tvWidget, "------tvWidget");
      if (tvWidget !== null) {
        tvWidget.onChartReady(() => {
          // if (tvWidget.activeChart().canZoomOut()) {
          //   tvWidget.activeChart().zoomOut();
          // }

          if (curBot.action == "closed") {
            // Remove existing order lines before creating new ones
            if (lowPriceLine.current) {
              lowPriceLine.current.remove();
              lowPriceLine.current = null;
            }

            if (gridLevels.current && gridLevels.current.length > 0) {
              gridLevels.current.forEach((line) =>
                tvWidget.chart().removeEntity(line)
              );
              gridLevels.current = [];
            }

            if (highPriceLine.current) {
              highPriceLine.current.remove();
              highPriceLine.current = null;
            }

            if (breakingEvent.current) {
              tvWidget.chart().removeEntity(breakingEvent.current);
            }
            if (breakingEvent.current) {
              breakingEvent.current.remove();
              breakingEvent.current = null; // Reset the reference
            }
            if (stopLoss.current) {
              tvWidget.chart().removeEntity(stopLoss.current);
            }
            flagsLine.current.forEach((line) =>
              tvWidget.chart().removeEntity(line)
            );
            if (flagsLine.current) {
              // tvWidget.activeChart().removeAllShapes();
              flagsLine.current.forEach((line) =>
                tvWidget.chart().removeEntity(line)
              );
              // tvWidget.chart().clearMarks();
              flagsLine.current = []; // Reset the reference
              tvWidget.activeChart().removeEntity();
            }
          }
          if (curBot.actives?.length > 0) {
            // && botData?.crtBot
            let lp =
              (isEmpty(curBot.status) || curBot.status != "closed") &&
              curBot.actives[0]?.price &&
              curBot.actives[0].side == "buy"
                ? parseFloat(curBot.actives[0].price)
                : parseFloat(markPrice);
            let hp =
              (isEmpty(curBot.status) || curBot.status != "closed") &&
              curBot.actives?.length > 0 &&
              curBot.actives[curBot.actives.length - 1].side == "sell"
                ? parseFloat(curBot.actives[curBot.actives.length - 1]?.price)
                : parseFloat(markPrice);
            if (curBot.action == "create") {
              lp = curBot.actives[0]?.price
                ? parseFloat(curBot.actives[0].price)
                : parseFloat(markPrice);
              hp =
                curBot.actives?.length > 0
                  ? parseFloat(curBot.actives[curBot.actives.length - 1]?.price)
                  : parseFloat(markPrice);
            }
            const priceScale_Reset = tvWidget
              .activeChart()
              .getPanes()[0]
              .getMainSourcePriceScale();
            priceScale_Reset.setAutoScale(true);
            console.log(lp, "------lp");
            console.log(hp, "------hp");
            if (lp > 0 && hp > 0) {
              const priceScale = tvWidget
                .activeChart()
                .getPanes()[0]
                .getRightPriceScales()[0];
              priceScale.setVisiblePriceRange({
                from: lp - (lp * 3) / 100,
                to: hp + (hp * 3) / 100,
              });
            }

            // chart line zoom
            // let minPrice = Number.MAX_SAFE_INTEGER;
            // let maxPrice = Number.MIN_SAFE_INTEGER;

            // curBot.actives.forEach((order) => {
            //   const price = parseFloat(order.price);
            //   if (!isNaN(price)) {
            //     if (order.side === "buy") {
            //       minPrice = Math.min(minPrice, price);
            //     } else if (order.side === "sell") {
            //       maxPrice = Math.max(maxPrice, price);
            //     }
            //   }
            // });
            // console.log(minPrice, "---------minPrice");
            // console.log(maxPrice, "---------maxPrice");
            // console.log(
            //   Number.MAX_SAFE_INTEGER,
            //   "---------Number.MAX_SAFE_INTEGER"
            // );
            // console.log(
            //   Number.MIN_SAFE_INTEGER,
            //   "---------Number.MIN_SAFE_INTEGER"
            // );
            // if (
            //   minPrice < Number.MAX_SAFE_INTEGER &&
            //   maxPrice > Number.MIN_SAFE_INTEGER
            // ) {
            //   const priceScale_Reset = tvWidget
            //     .activeChart()
            //     .getPanes()[0]
            //     .getMainSourcePriceScale();
            //   priceScale_Reset.setAutoScale(true);

            //   console.log(
            //     minPrice,
            //     "------314",
            //     minPrice - (minPrice * 10) / 100
            //   );
            //   console.log(
            //     maxPrice,
            //     "------319",
            //     maxPrice + (maxPrice * 10) / 100
            //   );

            //   const priceScale = tvWidget
            //     .activeChart()
            //     .getPanes()[0]
            //     .getRightPriceScales()[0];

            //   priceScale.setVisiblePriceRange({
            //     from: minPrice - (minPrice * 10) / 100,
            //     to: maxPrice + (maxPrice * 10) / 100,
            //   });
            // }
          }
          tvWidget.headerReady().then(() => {
            if (activeCnt > 0) {
              // Remove existing order lines before creating new ones
              if (lowPriceLine.current) {
                lowPriceLine.current.remove();
                lowPriceLine.current = null;
              }

              if (gridLevels.current && gridLevels.current.length > 0) {
                gridLevels.current.forEach((line) =>
                  tvWidget.chart().removeEntity(line)
                );
                gridLevels.current = [];
              }

              if (highPriceLine.current) {
                highPriceLine.current.remove();
                highPriceLine.current = null;
              }

              if (breakingEvent.current) {
                tvWidget.chart().removeEntity(breakingEvent.current);
              }
              if (stopLoss.current) {
                tvWidget.chart().removeEntity(stopLoss.current);
              }

              curBot.actives.forEach((item, index) => {
                if (curBot.action == "create") {
                  if (index == 0) {
                    // let lProp = {
                    //   shape: "horizontal_line",
                    //   overrides: {
                    //     linecolor: "rgb(25, 118, 210)",
                    //     fillBackground: true,
                    //     linewidth: 4,
                    //     text: "Low Price",
                    //   },
                    // };
                    // const circleCoordinates = {
                    //   price: item.price,
                    // };
                    // lowPriceLine.current = tvWidget
                    //   .activeChart()
                    //   // .setLineStyle(0)
                    //   .createShape(circleCoordinates, lProp);

                    lowPriceLine.current = tvWidget
                      .chart()
                      .createOrderLine()
                      .setLineStyle(1)
                      .setQuantityBackgroundColor("rgb(25, 118, 210)")
                      .setBodyBorderColor("rgb(25, 118, 210)")
                      .setQuantityBorderColor("rgb(25, 118, 210)")
                      .setBodyTextColor("rgb(25, 118, 210)")
                      .setLineColor("rgb(25, 118, 210)")
                      .setLineLength(4)
                      .setLineWidth(2)
                      .setText("Low Price")
                      .setQuantity("")
                      .setPrice(item.price);
                  } else if (activeCnt == index + 1) {
                    // let hProp = {
                    //   shape: "horizontal_line",
                    //   overrides: {
                    //     linecolor: "rgb(25, 118, 210)",
                    //     fillBackground: true,
                    //     linewidth: 4,
                    //     text: "High Price",
                    //   },
                    // };
                    // const circleCoordinates = {
                    //   price: item.price,
                    // };
                    // highPriceLine.current = tvWidget
                    //   .activeChart()
                    //   // .setLineStyle(0)
                    //   .createShape(circleCoordinates, hProp);

                    highPriceLine.current = tvWidget
                      .chart()
                      .createOrderLine()
                      .setLineStyle(1)
                      .setQuantityBackgroundColor("rgb(25, 118, 210)")
                      .setBodyBorderColor("rgb(25, 118, 210)")
                      .setQuantityBorderColor("rgb(25, 118, 210)")
                      .setBodyTextColor("rgb(25, 118, 210)")
                      .setLineColor("rgb(25, 118, 210)")
                      .setLineLength(4)
                      .setLineWidth(2)
                      .setText("High Price")
                      .setQuantity("")
                      .setPrice(item.price);
                  } else {
                    const lineColor =
                      // markPrice >= item.price
                      item.side == "buy"
                        ? "rgb(22, 112, 72)" // green
                        : "rgb(157, 62, 67)"; //red

                    let Lprop = {
                      shape: "horizontal_line",
                      overrides: {
                        linecolor: lineColor,
                        fillBackground: false,
                        linewidth: 1,
                        price_note: false,
                        showPrice: false,
                      },
                      lock: true,
                      disableSelection: true,
                      disableSave: true,
                      disableUndo: true,
                    };
                    const circleCoordinates = {
                      price: item.price,
                    };
                    const level = tvWidget
                      .activeChart()
                      .createShape(circleCoordinates, Lprop);

                    gridLevels.current.push(level);
                  }
                } else if (
                  curBot.action == "detail" &&
                  curBot.status == "open"
                ) {
                  const lineColor =
                    // markPrice >= item.price
                    item.side == "buy"
                      ? "rgb(22, 112, 72)" // green
                      : "rgb(157, 62, 67)"; //red
                  const lineProperties1 = {
                    shape: "horizontal_line",
                    overrides: {
                      linecolor: lineColor,
                      fillBackground: false,
                      linewidth: 1,
                      showPrice: false,
                    },
                    lock: true,
                    disableSelection: true,
                    disableSave: true,
                    disableUndo: true,
                  };
                  const circleCoordinates = {
                    price: item.price,
                  };
                  const level = tvWidget
                    .activeChart()
                    .createShape(circleCoordinates, lineProperties1);
                  gridLevels.current.push(level);
                }
              });
            } else {
              if (gridLevels.current && gridLevels.current.length > 0) {
                gridLevels.current.forEach((line) =>
                  tvWidget.chart().removeEntity(line)
                );
                gridLevels.current = [];
              }
            }
            flagsLine.current.forEach((line) =>
              tvWidget.chart().removeEntity(line)
            );
            console.log(flagsLine, "----------383", gridLevels);
            if (filledCnt > 0) {
              if (tvWidget !== null) {
                tvWidget.onChartReady(() => {
                  // const newTimeZone = "Asia/Kolkata"; // Set your custom time zone
                  // tvWidget.chart().setTimezone(newTimeZone);

                  tvWidget.headerReady().then(() => {
                    flagsLine.current.forEach((line) =>
                      tvWidget.chart().removeEntity(line)
                    );

                    tvWidget.activeChart().removeEntity();

                    flagsLine.current = [];
                    // flagsLine.current = [];

                    let range = tvWidget.activeChart().getVisibleRange();
                    let minVisibleTime = range.from;
                    let maxVisibleTime = range.to;

                    curBot.filled.forEach((item) => {
                      // console.log(
                      //   minVisibleTime,
                      //   maxVisibleTime,
                      //   converttime(item.createdAt),
                      //   "minVisibleTimeminVisibleTimeminVisibleTimeminVisibleTime"
                      // );

                      let circleCoordinates = {
                        time: converttime(item.date),
                        price: item.price, // Set the y-coordinate based on the price where you want the circle
                      };

                      const circleProperties = {
                        shape: "icon",
                        overrides: {
                          color: item.side == "buy" ? "#039345" : "red",
                          size: 20,
                        },
                        lock: true,
                        disableSelection: true,
                        disableSave: true,
                        disableUndo: true,
                        icon: 0xf192,
                      };
                      if (
                        converttime(item.date) >= minVisibleTime &&
                        converttime(item.date) <= maxVisibleTime
                      ) {
                        const flag = tvWidget
                          .chart()
                          .createShape(circleCoordinates, circleProperties);
                        flagsLine.current.push(flag);
                      }
                    });
                  });

                  tvWidget
                    .chart()
                    .onDataLoaded()
                    .subscribe(null, function (interval, obj) {
                      let range = tvWidget.activeChart().getVisibleRange();
                      let minVisibleTime = range.from;
                      let maxVisibleTime = range.to;
                      curBot.filled.forEach((item) => {
                        let circleCoordinates = {
                          time: converttime(item.date),
                          price: item.price, // Set the y-coordinate based on the price where you want the circle
                        };

                        const circleProperties = {
                          shape: "icon",
                          overrides: {
                            color: item.side == "buy" ? "#039345" : "red",
                            size: 20,
                          },
                          lock: true,
                          disableSelection: true,
                          disableSave: true,
                          disableUndo: true,
                          icon: 0xf192,
                        };

                        if (
                          converttime(item.date) >= minVisibleTime &&
                          converttime(item.date) <= maxVisibleTime
                        ) {
                          const flag = tvWidget
                            .chart()
                            .createShape(circleCoordinates, circleProperties);
                          flagsLine.current.push(flag);
                        }
                      });
                    });
                });
              }
            }
            // if (filledCnt > 0) {

            //   // if (flagsLine.current && flagsLine.current.length > 0) {
            //   flagsLine.current.forEach((line) =>
            //     tvWidget.chart().removeEntity(line)
            //   );
            //   // }
            //   tvWidget.activeChart().removeEntity();
            //   flagsLine.current = [];

            //   let range = tvWidget.activeChart().getVisibleRange();
            //   let minVisibleTime = range.from;
            //   let maxVisibleTime = range.to;

            //   curBot.filled.forEach((item) => {
            //     // let timestamp = momentFormat(item.date);
            //     // let timestamp = new Date(item.date).toUTCString();
            //     // timestamp = new Date(timestamp).getTime();
            //     let timestamp = item.date;
            //     const circleCoordinates = {
            //       // time: new Date(item.date).getTime() / 1000,
            //       // time: Math.floor(new Date(item.createdAt).getTime() / 1000),

            //       time: converttime(timestamp),
            //       price: item.price, // Set the y-coordinate based on the price where you want the circle
            //     };
            //     const circleProperties = {
            //       shape: "icon",
            //       overrides: {
            //         color: item.side == "buy" ? "#039345" : "red",
            //         size: 20,
            //       },
            //       lock: true,
            //       disableSelection: true,
            //       disableSave: true,
            //       disableUndo: true,
            //       icon: 0xf192,
            //     };

            //     if (
            //       converttime(timestamp) >= minVisibleTime &&
            //       converttime(timestamp) <= maxVisibleTime
            //     ) {
            //       const flag = tvWidget
            //         .activeChart()
            //         .createShape(circleCoordinates, circleProperties);

            //       flagsLine.current.push(flag);
            //     }
            //   });

            //   tvWidget
            //     .chart()
            //     .onDataLoaded()
            //     .subscribe(null, function (interval, obj) {
            //       let range = tvWidget.activeChart().getVisibleRange();
            //       let minVisibleTime = range.from;
            //       let maxVisibleTime = range.to;

            //       curBot.filled.forEach((item) => {
            //         // let timestamp = momentFormat(item.date);
            //         // let timestamp = new Date(item.date).toUTCString();
            //         // timestamp = new Date(timestamp).getTime();
            //         let timestamp = item.date;

            //         const circleCoordinates = {
            //           // time: new Date(item.date).getTime() / 1000,
            //           // time: Math.floor(new Date(item.createdAt).getTime() / 1000),
            //           time: converttime(timestamp),
            //           price: item.price, // Set the y-coordinate based on the price where you want the circle
            //         };
            //         const circleProperties = {
            //           shape: "icon",
            //           overrides: {
            //             color: item.side == "buy" ? "#039345" : "red",
            //             size: 20,
            //           },
            //           lock: true,
            //           disableSelection: true,
            //           disableSave: true,
            //           disableUndo: true,
            //           icon: 0xf192,
            //         };
            //         if (
            //           converttime(timestamp) >= minVisibleTime &&
            //           converttime(timestamp) <= maxVisibleTime
            //         ) {
            //           const flag = tvWidget
            //             .chart()
            //             .createShape(circleCoordinates, circleProperties);

            //           flagsLine.current.push(flag);
            //         }
            //       });
            //     });
            // }
            else {
              if (flagsLine.current) {
                // tvWidget.activeChart().removeAllShapes();
                flagsLine.current.forEach((line) =>
                  tvWidget.chart().removeEntity(line)
                );
                // tvWidget.chart().clearMarks();
                flagsLine.current = []; // Reset the reference
              }
            }

            if (curBot.breakingEvent && curBot.breakingEvent > 0) {
              const beProp = {
                shape: "horizontal_line",
                overrides: {
                  linecolor: "rgb(255, 255, 0)",
                  fillBackground: true,
                  linewidth: 2,
                  showPrice: false,
                },
              };
              const beCoord = {
                price: curBot.breakingEvent,
              };
              if (breakingEvent.current) {
                breakingEvent.current.remove();
              }
              breakingEvent.current = tvWidget
                .chart()
                .createOrderLine()
                .setLineStyle(0)
                .setQuantityBackgroundColor("rgb(255, 255, 0)")
                .setBodyBorderColor("rgb(255, 255, 0)")
                .setQuantityBorderColor("rgb(255, 255, 0)")
                .setBodyTextColor("black")
                .setLineColor("rgb(255, 255, 0)")
                .setLineLength(100)
                .setLineWidth(2)
                .setExtendLeft(false)
                .setText("Break even price")
                .setQuantity("")
                .setPrice(curBot.breakingEvent);
              // .activeChart()
              // .createShape(beCoord, beProp);

              // breakingEvent
            } else {
              // Remove the breakingEvent line when the condition is not met
              if (breakingEvent.current) {
                breakingEvent.current.remove();
                breakingEvent.current = null; // Reset the reference
              }
            }
            if (
              curBot.stopLoss &&
              curBot.stopLoss > 0 &&
              curBot.status != "closed"
            ) {
              const beProp = {
                shape: "horizontal_line",
                overrides: {
                  linecolor: "rgb(200, 7, 7)",
                  fillBackground: true,
                  linewidth: 2,
                },
              };
              const beCoord = {
                price: curBot.stopLoss,
              };
              if (stopLoss.current) {
                stopLoss.current.remove();
              }
              stopLoss.current = tvWidget
                .chart()
                .createOrderLine()
                .setLineStyle(0)
                .setExtendLeft(false)
                .setQuantityBackgroundColor("rgb(200, 7, 7)")
                .setBodyBorderColor("rgb(200, 7, 7)")
                .setQuantityBorderColor("rgb(200, 7, 7)")
                .setBodyTextColor("rgb(200, 7, 7)")
                .setLineColor("rgb(200, 7, 7)")
                .setLineLength(100)
                .setLineWidth(4)
                .setText("Stop Loss")
                .setQuantity("")
                .setPrice(curBot.stopLoss);
              // .activeChart()
              // .createShape(beCoord, beProp);
            } else {
              // Remove the Take Profit line when the condition is not met
              if (stopLoss.current) {
                stopLoss.current.remove();
                stopLoss.current = null; // Reset the reference
              }
            }
          });

          // tvWidget
          //   .chart()
          //   .onIntervalChanged()
          //   .subscribe(null, function (interval, obj) {
          //     flagsLine.current.forEach((line) =>
          //       tvWidget.chart().removeEntity(line)
          //     );
          //   });
        });
      }
    }
  }, [curBot, tvWidget, botData?.openStatus]);

  const converttime = (time) => {
    let intervalDuration = props.interval == "D" ? 86400000 : 60000;
    let rem = time % intervalDuration;
    return (time - rem) / 1000; // Aligned timestamp in seconds
  };
  console.log(params.pair, "-------params.pair");
  return (
    // <div id={props.containerId} className={"chartcontainer"} />;
    <div ref={chartContainerRef} className={"TVChartContainer"} />
  );
};

GridChart.propTypes = {
  symbol: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  datafeedUrl: PropTypes.string.isRequired,
  libraryPath: PropTypes.string.isRequired,
  chartsStorageUrl: PropTypes.string.isRequired,
  chartsStorageApiVersion: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  fullscreen: PropTypes.string.isRequired,
  autosize: PropTypes.string.isRequired,
  studiesOverrides: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
};

GridChart.defaultProps = {
  symbol: "BTCUSDT",
  interval: "1",
  containerId: "tv_chart_container",
  datafeedUrl: chartUrl + "/api/chart",
  // datafeedUrl:
  //   "https://api.coindcx.com/api/v1/chart/history_v3?symbol=BTCINR&resolution=1&from=1637539200&to=1637648173",
  libraryPath: "/charting_library/", //live
  chartsStorageUrl: "",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
  style: 2,
  has_no_volume: false,
  indicators: "volume",
  time_frames: [
    {
      text: "3M",
      resolution: "720",
      description: "3 months in 12 hours intervals",
      title: "3m",
    },

    {
      text: "1M",
      resolution: "360",
      description: "1 month in 6 hours intervals",
      title: "1m",
    },

    {
      text: "7d",
      resolution: "60",
      description: "7 days in 1 hour intervals",
      title: "7d",
    },

    {
      text: "3d",
      resolution: "30",
      description: "3 days in 30 minutes intervals",
      title: "3d",
    },

    {
      text: "1d",
      resolution: "15",
      description: "1 day in 15 minutes intervals",
      title: "1d",
    },

    {
      text: "6h",
      resolution: "5",
      description: "6 hours in 5 minutes intervals",
      title: "6h",
    },

    {
      text: "1h",
      resolution: "1",
      description: "1 hour in 1 minute intervals",
      title: "1h",
    },
  ],
};

export default GridChart;
