import { useState, useEffect, Fragment, useContext } from "react";
import isEmpty from "../../lib/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { Modal, InputGroup, Form, Accordion, Dropdown } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { firstCapital, firstOnCap } from "../../lib/String";
import { dateHours, dateTimeFormat } from "../../lib/dateFilter";
import { toFixed, toFixedDown, toPercentage } from "../../lib/roundOf";

// import component
import BotPerformance from "./BotPerformance";
import BotDetailSettings from "./BotDetailSettings";

// action
import {
  botDetail,
  closeBot,
  modifyGrid,
  curBotDetail,
  openSB,
  getGCHistory,
} from "../../actions/gridbot";
import { toastAlert } from "../../lib/toastAlert";
import SocketContext from "../Context/SocketContext";

const Datas = {
  tpRatio: 1,
  isTP: false,
  sl: 1,
  isSL: false,
};
export default function GridBotDetails(props) {
  const { params, markPrice, markResult, FeeandDecimal, updateParams } = props;

  const socketContext = useContext(SocketContext);

  // redux
  const botData = useSelector((state) => state?.curGridBot);

  const dispatch = useDispatch();

  // state
  const [actives, setActives] = useState([]);
  const [ordHis, setOrdHis] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [tradedata, setTradeData] = useState(Datas);
  const [mLoader, setMLoader] = useState(false);
  const [baStatus, setBAStatus] = useState(false);
  const [gridbotMod, setGridBotMod] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSB, setSB] = useState(false);
  const [isMSB, setMSB] = useState(false);
  const [sgData, setSGData] = useState({});
  const [pnlData, setPnl] = useState({ pnl: 0, pnlPct: 0 });
  const [bAProfit, setBAProfit] = useState({
    totBProfit: 0,
    totBPnl: 0,
  });
  const [bProfit, setBProfit] = useState({});
  const [stopGrid, setSG] = useState("cancel");

  // redux
  const curBot = useSelector((state) => state.curGridBot);
  const oSB = useSelector((state) => state.openSBGrid);

  const { tpRatio, isTP, sl, isSL } = tradedata;
  let { totBProfit, totBPnl } = bAProfit;
  // Function to handle tab click
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleStatusChange = async (Runstatus) => {};

  //new
  const onGridModHide = async () => {
    setGridBotMod(false);
  };
  useEffect(() => {
    if (oSB && oSB.type == "open") {
      setSB(true);
      setMSB(true);
      setSGData(oSB.row);
    }
  }, [oSB]);
  const handleCloseBot = async (e, type) => {
    e.preventDefault();
    try {
      let reqData = {
        exchange: params.exchange,
        orderId: curBot._id,
        type: type,
        pairName: splitData(params.pair),
      };
      setLoader(true);
      const { status, message } = await closeBot(reqData);
      setLoader(false);
      if (status == "success") {
        toastAlert("success", message, "save");
        openSB({ type: "closed" }, dispatch);
        updateParams({
          exchange: params.exchange,
          bot: "grid",
          pair: params.pair,
        });
        // window.location.reload();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {}
  };
  const handleMB = (msbStatus, sbStatus) => {
    setMSB(msbStatus);
    setSB(sbStatus);
    setBAStatus(false);
  };
  useEffect(() => {
    socketContext.socket.on("openGridSocket", (result) => {
      console.log(result, "---------109");
      if (
        splitData(params.pair) == result.pairName &&
        params.exchange == result.exchange
      ) {
        for (let item of result.data) {
          if (item.pairName == splitData(params.pair)) {
            fetchBotDetail(item._id);
          }
        }
      }
    });
  }, []);
  const fetchHistory = async () => {
    const { result } = await getGCHistory({
      botId: curBot._id,
    });
    setBProfit(result.botHistory[0]);
  };
  useEffect(() => {
    socketContext.socket.on("openGridSocket", (result) => {
      console.log(result, "---------136", params);
      if (
        splitData(params.pair) == result.pairName &&
        params.exchange == result.exchange
      ) {
        for (let item of result.data) {
          if (item.pairName == splitData(params.pair)) {
            // fetchBProfit();
            fetchHistory();
          }
        }
      }
    });
  }, []);
  useEffect(() => {
    fetchBProfit();
  }, [bProfit]);
  let fetchBProfit = () => {
    let totBProfit = 0,
      totBPnl = 0;
    try {
      let investment = calInvClos(botData.initial);
      if (!isEmpty(bProfit)) {
        totBProfit = bProfit.pnl;
        console.log(investment, "------144");
        totBPnl = (bProfit.pnl / investment) * 100;
      }
      setBAProfit({
        totBProfit,
        totBPnl,
      });
    } catch (err) {}
  };
  const fetchBotDetail = async (id) => {
    try {
      let reqData = {
        botId: id,
      };
      const { status, result } = await botDetail(reqData);
      if (status == "success") {
        setActives(result.actives);
        setOrdHis(result.ordHis);
        avgCalc(result.actives);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };

  const handleDownload = async (action) => {
    if (action == "history") {
      const csvData = [
        [
          "Date & Time",
          "Side",
          "Amount",
          "Price",
          "Action",
          "Total",
          "Fee",
          "FeeUSDT",
          "Profit",
          "%",
          "Exchange",
        ],
      ];

      for (let item of ordHis) {
        let prevOrd = item?.prevOrd;
        let pnlPer = 0;
        if (!isEmpty(prevOrd)) {
          pnlPer = item.pnl / (prevOrd.price * prevOrd.quantity);
        }
        let rowFilled = feeFilled(item.feeFilled);
        let arrData = [
          dateTimeFormat(item.orderDate),
          firstOnCap(item.buyorsell),
          `${toFixed(item.quantity, 6)} ${item.firstCurrency}`,
          item.price,
          item.gridAction,
          item.quantity && item.price
            ? toFixedDown(
                item.price * item.quantity,
                FeeandDecimal?.priceDecimal
              )
            : "-",
          `${toFixed(rowFilled.commission, 8)} ${rowFilled.commissionAsset}`,
          `${toFixed(rowFilled.commissionUSDT, 8)} USDT`,
          item.pnl == "-" ? item.pnl : toFixed(item.pnl, 2),
          toFixed(pnlPer * 100, 2),
          params.exchange,
        ];
        csvData.push(arrData);
      }

      // Convert the data to CSV format
      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((row) => row.join(",")).join("\n");

      // Create a link element
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link);

      // Trigger the download
      link.click();
    } else if (action == "openOrder") {
      const csvData = [
        [
          "Pair / Bot type",
          "Date & Time",
          "Side",
          "Action",
          "Amount",
          "Price",
          "Status",
          "Exchange",
        ],
      ];

      for (let item of actives) {
        let arrData = [
          item.pairName,
          dateTimeFormat(item.orderDate),
          firstOnCap(item.buyorsell),
          item.action,
          `${toFixed(item.quantity, 6)} ${item.firstCurrency}`,
          item.price,
          firstCapital(item.status),
          params.exchange,
        ];
        csvData.push(arrData);
      }

      // Convert the data to CSV format
      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((row) => row.join(",")).join("\n");

      // Create a link element
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "active.csv");
      document.body.appendChild(link);

      // Trigger the download
      link.click();
    }
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  const avgCalc = (actives) => {
    try {
      let total = 0,
        quantity = 0;
      if (actives && actives.length > 0) {
        for (let item of actives) {
          if (item.buyorsell == "sell") {
            let prevOrd = item.prevOrd;
            total += prevOrd.quantity * prevOrd.price;
            quantity += prevOrd.quantity;
          }
        }
      }
      let avgPrice = total / quantity;
      let avgPer = (avgPrice * 0.2) / 100;
      avgPrice = avgPrice + avgPer;
      curBotDetail(
        {
          breakingEvent: avgPrice,
        },
        dispatch
      );
      return avgPrice;
    } catch (err) {
      console.log(err, "------78");
      return 0;
    }
  };

  useEffect(() => {
    setSB(false);
    if (!isEmpty(curBot)) {
      fetchBotDetail(curBot._id);
      fetchHistory();
      let botValue = {};
      if (curBot.stopLoss > 0) {
        botValue = { isSL: true, sl: curBot.stopLoss };
      }
      if (curBot.tpRatio > 0) {
        botValue.isTP = true;
        botValue.tpRatio = curBot.tpRatio;
      }
      let formValue = { ...tradedata, ...botValue };
      setTradeData(formValue);
    }
  }, [curBot?._id]);

  useEffect(() => {
    setSB(false);
  }, [curBot]);

  const activeCol = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type">
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{row.pairName}</p>
            <div className="table_pairtype">
              <p className="mb-0">GRID</p>
              {/* <p className="mb-0"> Long</p>  */}
            </div>
          </div>
        );
      },
    },
    {
      name: "Date & Time",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{dateTimeFormat(row.orderDate)}</p>
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Side",
      selector: (row) => {
        return (
          <p
            className={
              row && row.buyorsell == "sell" ? "redText mb-0" : "greenTxt mb-0"
            }
          >
            {firstOnCap(row.buyorsell)}
          </p>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{firstOnCap(row.action.replace("_", " "))}</p>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixed(row.quantity, 6)}{" "}
            <span className="greyTxt">{row.firstCurrency}</span>
          </p>
        );
      },
      width: "150px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: (
        <div>
          <p className="mb-0">Status</p>
        </div>
      ),
      selector: (row) => {
        return (
          <div>
            <p className="greyTxt mb-2">{firstCapital(row.status)}</p>
            {/* <p className="tablebot_status mb-0">TU</p>  */}
          </div>
        );
      },
    },
  ];

  const ordHisCol = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type">
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Date & Time",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{dateTimeFormat(row.orderDate)}</p>
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Side",
      selector: (row) => {
        return (
          <p
            className={
              row && row.buyorsell == "sell" ? "redText mb-0" : "greenTxt mb-0"
            }
          >
            {firstOnCap(row.buyorsell)}
          </p>
        );
      },
      width: "70px",
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixed(row.quantity, 6)}{" "}
            <span className="greyTxt">{row.firstCurrency}</span>
          </p>
        );
      },
      width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Action",
      selector: (row) => firstOnCap(row.gridAction.replace("_", " ")),
      width: "120px",
    },
    {
      name: "Total",
      selector: (row) => {
        return (
          <div>
            <p className="mb-0">
              {row.quantity &&
                row.price &&
                toFixed(
                  row.price * row.quantity,
                  FeeandDecimal?.priceDecimal
                )}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
      width: "130px",
    },
    {
      name: "Fee",
      width: "200px",
      selector: (row) => {
        let rowFilled = feeFilled(row.feeFilled);
        return (
          <Fragment>
            <p className="mb-0 white">
              {toFixed(rowFilled.commission, 8)}{" "}
              <span className="white">{rowFilled.commissionAsset}</span>
            </p>
            <p className="mb-0 white">
              {toFixed(rowFilled.commissionUSDT, 8)}{" "}
              <span className="white">USDT</span>
            </p>
          </Fragment>
        );
      },
    },
    {
      name: "Profit",
      selector: (row) => {
        let prevOrd = row?.prevOrd;
        let pnlPer = 0;
        if (!isEmpty(prevOrd)) {
          pnlPer = row.pnl / (prevOrd.price * prevOrd.quantity);
        }
        return (
          <div>
            {row.pnl == "-" ? (
              <p className="mb-0">{row.pnl}</p>
            ) : (
              <Fragment>
                <p className={row.pnl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
                  {toFixedDown(pnlPer * 100, 2)} %
                </p>
                <p className="mb-0">
                  {toFixedDown(row.pnl, 2)}{" "}
                  <span className="greyTxt">{row.secondCurrency}</span>
                </p>
              </Fragment>
            )}
          </div>
        );
      },
    },
    // {
    //   name: "%",
    //   selector: (row) => {
    //     let prevOrd = row?.prevOrd;
    //     let pnlPer = 0;
    //     if (!isEmpty(prevOrd)) {
    //       pnlPer = row.pnl / (prevOrd.price * prevOrd.quantity);
    //     }
    //     console.log(pnlPer, "---------------453");
    //     return (
    //       <div>
    //         {row.pnl == "-" ? (
    //           <p className="mb-0">{row.pnl}</p>
    //         ) : (
    //           <p
    //             className={
    //               row && row.pnl < 0 ? "redText mb-0" : "greenTxt mb-0"
    //             }
    //           >
    //             {toFixed(pnlPer * 100, 2)} %
    //           </p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];
  const feeFilled = (feeFilled) => {
    let commission = 0,
      commissionUSDT = 0,
      commissionAsset = "";
    try {
      for (let feeitem of feeFilled) {
        commissionAsset = feeitem?.commissionAsset;
        commission += parseFloat(feeitem?.commission);
        commissionUSDT += parseFloat(feeitem?.commissionUSDT);
      }
      return {
        commissionAsset,
        commission,
        commissionUSDT,
      };
    } catch (err) {
      return {
        commissionAsset,
        commission,
        commissionUSDT,
      };
    }
  };
  const handletradeChange = (e) => {
    let { name, value } = e.target;
    let formValue = tradedata;

    if (name == "tpRatio" || name == "sl") {
      if (/[^0-9.]/.test(value)) return;
    }
    setTradeData({ ...formValue, ...{ [name]: value } });

    // customAmountSetup(newTd);
    // if (value) {
    //   setValidErr({});
    //   setLoader(false);
    // }
    if (name == "sl" && value > 0) {
      curBotDetail(
        {
          stopLoss: parseFloat(value),
        },
        dispatch
      );
    }
  };
  const handleModifyBot = async (e) => {
    try {
      setMLoader(true);
      let reqData = {
        pairName: curBot.pairName,
        exchange: params.exchange,
        botId: curBot._id,
        isSL: isSL,
        isTP: isTP,
        tpRatio: tpRatio,
        sl: sl,
      };

      const { status, message } = await modifyGrid(reqData);
      setMLoader(false);
      let tStatus = status == "success" ? "success" : "error";
      toastAlert(tStatus, message, tStatus);
      setBAStatus(false);
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const handleModify = (e) => {
    const { name, checked } = e.target;
    let formValue = tradedata;
    setTradeData({
      ...formValue,
      ...{ [name]: checked },
    });
  };

  const handleCloseicon = (type) => {
    if (type == "details") {
      setSB(true);
      setMSB(false);
    }
  };
  useEffect(() => {
    fetchPNL(markPrice);
  }, [markPrice, botData]);
  useEffect(() => {
    // socket
    // let socketTicker = "socketTicker" + botData.exchange;
    // socketContext[socketTicker].on("marketPrice", (result) => {
    if (markResult && markResult.pairId == botData.pairId) {
      fetchPNL(markResult.data.markPrice);
    }
    // });
  }, [markResult, botData.pairId]); // [botData.pairId, botData.exchange]);
  const fetchPNL = (mPrice) => {
    let pnl = calcPnl(botData.initial, botData.actives, mPrice);
    setPnl({ pnl: pnl.totalpnl, pnlPct: pnl.totalPnlPct });
  };
  const calInvClos = (filled) => {
    try {
      let totalInv = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };
  const calcPnl = (initial, actives, mPrice) => {
    try {
      let investment = calInv(actives);
      // let totalpnl = 0,
      //   totalPnlPct = 0;
      // if (actives && actives.length > 0) {
      //   for (let el of actives) {
      //     if (el.side == "sell") {
      //       let prevOrd = el.prevOrd;
      //       let exitValue = mPrice * el.quantity;
      //       // let marketPrice = mPrice - (mPrice * 0.1) / 100;
      //       let pnl = exitValue - prevOrd.price * prevOrd.quantity;
      //       console.log(pnl, "-------655", exitValue);
      //       if (botData.exchange == "Binance") {
      //         pnl = pnl - (exitValue * 0.1) / 100;
      //       }
      //       console.log(pnl, "-------659");
      //       totalpnl += pnl;
      //       totalPnlPct += (pnl / investment) * 100;
      //     }
      //   }
      // }
      let totalpnl = 0,
        totalPnlPct = 0;
      if (actives && actives.length > 0) {
        console.log(actives, "-------287");
        console.log(mPrice, "--------288");
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd,
              pnl;
            if (botData.exchange == "BitMart") {
              pnl =
                mPrice * prevOrd.quantity -
                (prevOrd.price * prevOrd.quantity + prevOrd.fee);
            } else if (botData.exchange == "Binance") {
              if (prevOrd.quantity == el.quantity) {
                let fee = !isEmpty(prevOrd.fee) ? prevOrd.fee : 0;
                let exitValue = mPrice * el.quantity;
                pnl = exitValue - fee - prevOrd.price * prevOrd.quantity;
                pnl = pnl - (exitValue * 0.1) / 100;
              } else {
                let exitValue = mPrice * prevOrd.execQty;
                pnl = exitValue - prevOrd.price * prevOrd.quantity;
                pnl = pnl - (exitValue * 0.1) / 100;
              }
            } else {
              pnl = mPrice * prevOrd.execQty - prevOrd.price * prevOrd.quantity;
            }
            totalpnl += pnl;
          }
        }
      }
      totalPnlPct = (totalpnl / investment) * 100;

      return { totalpnl, totalPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const calInv = (actives) => {
    try {
      let totalInv = 0;
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            totalInv = totalInv + prevOrd.price * prevOrd.quantity;
          }
        }
      }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };
  return (
    <>
      {!isSB && (
        <div className="card trade_card">
          <div className="card-header">
            <h2>GRID Bot Details</h2>
            <p>
              <i
                className="bi bi-x-circle-fill"
                onClick={() => handleCloseicon("details")}
              ></i>
            </p>
          </div>

          <div className="card-body">
            <div className="card_body_padding p-0">
              <div className="bot_preview_box_wrapper">
                <div className="botdetail_height">
                  <div className="p-2">
                    <div className="tab_btn mb-3">
                      <button
                        onClick={() => handleTabClick("tab1")}
                        className={activeTab === "tab1" ? "active" : ""}
                      >
                        Performance
                      </button>
                      <button
                        onClick={() => handleTabClick("tab2")}
                        className={activeTab === "tab2" ? "active" : ""}
                      >
                        Settings
                      </button>
                    </div>

                    {activeTab === "tab1" && (
                      <BotPerformance
                        params={params}
                        actives={actives}
                        markPrice={markPrice}
                        markResult={markResult}
                        FeeandDecimal={FeeandDecimal}
                      />
                    )}
                    {activeTab === "tab2" && (
                      <BotDetailSettings params={params} />
                    )}
                  </div>
                </div>
                {/* </Scrollbars>  */}
              </div>

              <div className="column_btngrp px-2">
                <div className="card_body_padding">
                  <button
                    className="primary_btn primary_black_btn m-0"
                    onClick={() => setGridBotMod(true)}
                  >
                    Bot orders
                  </button>
                </div>

                {curBot.status == "open" && (
                  <button
                    className="primary_btn primary_blue_btn m-0 "
                    // type="button"
                    // data-bs-toggle="offcanvas"
                    // data-bs-target="#offcanvasTop1"
                    // aria-controls="offcanvasTop"
                    onClick={() => setBAStatus(true)}
                  >
                    Bot actions
                  </button>
                )}
              </div>

              <div className="bot_actions_card pb-4">
                <div
                  class={
                    baStatus
                      ? "offcanvas offcanvas-top show"
                      : "offcanvas offcanvas-top"
                  }
                  tabindex="-1"
                  id="offcanvasTop1"
                  aria-labelledby="offcanvasTopLabel"
                  data-bs-scroll="true"
                >
                  <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasTopLabel">
                      Bot actions
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() => setBAStatus(false)}
                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    {/* <div
                    data-bs-toggle="modal"
                    data-bs-target="#buy_modal"
                    data-bs-dismiss="offcanvas"
                  >
                    <h6>Manual position averaging</h6>
                    <p>
                      Average the price of a position by manually adding funds
                      according to the current market price.
                    </p>
                  </div> */}

                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#modify_modal"
                      data-bs-dismiss="offcanvas"
                    >
                      <h6>Modify Bot</h6>
                      <p>Modify Bot</p>
                    </div>
                    <div
                      data-bs-dismiss="offcanvas"
                      onClick={() => handleMB(true, true)}
                    >
                      <h6>Stop Bot</h6>
                      <p>Stop the bot's operations completely.</p>
                    </div>

                    {/* {!isEmpty(RunStatus) && RunStatus == "Start" && (
                      <div
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleStatusChange("Pause")}
                      >
                        <h6>Stop Bot</h6>
                        <p>Stop the bot's operations completely.</p>
                      </div>
                    )}

                    {!isEmpty(RunStatus) && RunStatus == "Pause" && (
                      <div
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleStatusChange("Start")}
                      >
                        <h6>Start Bot</h6>
                        <p>Start the bot's operations completely.</p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMSB && isMSB && (
        <div className="card trade_card">
          <div className="card-header">
            <h2>Stop Grid Bot</h2>
            <p onClick={() => handleMB(false, false)}>
              <i className="bi bi-x-circle-fill"></i>
            </p>
          </div>

          <div className="card-body">
            <div className="card_body_padding p-0">
              <div className="bot_preview_box_wrapper">
                <div className="botdetail_height botdetail_height_chg">
                  <div className="p-2">
                    <div className="stopbot">
                      <div
                        class={
                          stopGrid == "cancel"
                            ? "form-check mb-2 active"
                            : "form-check mb-2"
                        }
                        onClick={() => setSG("cancel")}
                      >
                        <label class="form-check-label" for="flexRadioDefault1">
                          Cancel all orders and keep {sgData.firstCurrency}
                        </label>
                        <p>
                          Open orders will be closed and the current amount in
                          trade currency will be held in your balance.
                        </p>
                      </div>
                      <div
                        class={
                          stopGrid == "market"
                            ? "form-check mb-2 active"
                            : "form-check mb-2"
                        }
                        onClick={() => setSG("market")}
                      >
                        <label class="form-check-label" for="flexRadioDefault2">
                          Sell at market price
                        </label>
                        <p>Sell at market price and move the bot to history.</p>
                        <div class="bot_preview_box">
                          <div>
                            <span>Total PNL</span>
                            <span class="border_line"></span>
                            <span>
                              {toFixed(pnlData.pnl, 2)}{" "}
                              <span className="greyTxt">USDT</span> /{" "}
                              <span
                                className={
                                  pnlData.pnlPct > 0 ? "greenTxt" : "redText"
                                }
                              >
                                {toFixed(pnlData.pnlPct, 2)} %
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3">
                        <button
                          class="primary_btn primary_blue_btn w-50 cancel"
                          onClick={() => handleMB(false, false)}
                        >
                          Cancel
                        </button>
                        <button
                          class="primary_btn primary_blue_btn w-50"
                          disabled={loader}
                          onClick={(e) => handleCloseBot(e, stopGrid)}
                        >
                          {loader && <div class="loader"></div>}
                          {!loader && "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modify Boy Modal PopUp */}
      <div
        className="modal fade primary_modal"
        id="modify_modal"
        //data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="modify_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modify_modal_label">
                Modify Bot
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="accr_box">
                <Accordion defaultActiveKey={"mbot"}>
                  <Accordion.Item eventKey="mbot">
                    {/* <Accordion.Header>
                      <div>Position TP & SL</div>
                    </Accordion.Header> */}
                    <Accordion.Body>
                      <div className="box">
                        <div className="chk_box">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              onChange={(e) => handleModify(e)}
                              checked={isTP}
                              value={isTP}
                              name="isTP"
                            />
                            <label
                              class="form-check-label"
                              for="flexSwitchCheckDefault"
                            >
                              <i
                                class="bi bi-info-circle-fill me-2"
                                data-tooltip-id="my-tooltip-4"
                              ></i>
                              Take Profit
                            </label>
                            <ReactTooltip
                              id="my-tooltip-4"
                              place="bottom"
                              content={
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ color: "white" }}
                                  >
                                    Take Profit
                                  </p>
                                  <p className="mb-2">
                                    Configure the Take Profit percentage of the
                                    total PNL <br /> to close the bot when
                                    getting a profit. If a specified Take
                                    <br /> Profit level is reached or exceeded,
                                    the bot sells all the <br /> Base currency
                                    used and stops further operations.
                                  </p>
                                  .
                                </div>
                              }
                            />
                          </div>
                        </div>
                        {isTP && (
                          <div className="inputgrp_box">
                            <label className="grey">Total PNL, %</label>

                            <InputGroup className="w-100">
                              <Form.Control
                                aria-label="Username"
                                value={tpRatio}
                                name="tpRatio"
                                onChange={handletradeChange}
                                aria-describedby="basic-addon1"
                              />
                            </InputGroup>
                            {/* <span className="text-danger">
                              {validErr && validErr.tpRatio}
                            </span> */}
                          </div>
                        )}
                      </div>
                      <div className="box">
                        <div className="chk_box">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              onChange={(e) => handleModify(e)}
                              checked={isSL}
                              value={isSL}
                              name="isSL"
                            />
                            <label
                              class="form-check-label"
                              for="flexSwitchCheckDefault"
                            >
                              <i
                                class="bi bi-info-circle-fill me-2"
                                data-tooltip-id="my-tooltip-4"
                              ></i>
                              Stop Loss
                            </label>
                            <ReactTooltip
                              id="my-tooltip-4"
                              place="bottom"
                              content={
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ color: "white" }}
                                  >
                                    Stop Loss
                                  </p>
                                  <p className="mb-2">
                                    Configure stoploss options to close the bot
                                    when the <br /> price moves against the
                                    chosed direction. If the price
                                    <br /> reaches a specified Stop Loss level,
                                    the bot sells all the <br /> Base currency
                                    used and stops further operations.
                                  </p>
                                </div>
                              }
                            />
                          </div>
                        </div>
                        {isSL && (
                          <div className="inputgrp_box">
                            <label className="grey">Price</label>

                            <InputGroup className="w-100">
                              <Form.Control
                                aria-label="Username"
                                value={sl}
                                name="sl"
                                onChange={handletradeChange}
                                aria-describedby="basic-addon1"
                              />
                            </InputGroup>
                            {/* <span className="text-danger">
                              {validErr && validErr.sl}
                            </span> */}
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="text-center">
                <button
                  className="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={handleModifyBot}
                  disabled={mLoader}
                >
                  {mLoader && <div className="loader"></div>}
                  {!mLoader && "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={gridbotMod}
        onHide={onGridModHide}
        centered
        size="xl"
        className="modal fade primary_modal primary_modal_extra_large"
      >
        <Modal.Header closeButton>
          <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
            Bot orders
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="card trade_card trade_history_card">
            <div className="card-header">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="history-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#history"
                    type="button"
                    role="tab"
                    aria-controls="history"
                    aria-selected="true"
                  >
                    History
                  </button>
                </li>
                {curBot.status == "open" && (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="openorder-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#openorder"
                      type="button"
                      role="tab"
                      aria-controls="openorder"
                      aria-selected="false"
                    >
                      Open Orders{" "}
                    </button>
                  </li>
                )}
              </ul>
              <div className="d-flex flex-wrap gap-3">
                <p>
                  <span>Bot profit</span> $ {toFixed(totBProfit, 2)}{" "}
                  <span className={totBPnl > 0 ? "greenTxt" : "redText"}>
                    {` ${toFixed(totBPnl, 2)}`}%
                  </span>
                </p>
              </div>
            </div>
            <div className="card-body">
              <div className="bot_profit"></div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane active show"
                  id="history"
                  role="tabpanel"
                  aria-labelledby="history-tab"
                >
                  <div className="primary_table">
                    <DataTable columns={ordHisCol} data={ordHis} pagination />
                  </div>
                  <div>
                    <button
                      className="continue w-auto mx-auto"
                      onClick={() => handleDownload("history")}
                    >
                      Download CSV
                    </button>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="openorder"
                  role="tabpanel"
                  aria-labelledby="openorder-tab"
                >
                  <div className="primary_table">
                    <DataTable columns={activeCol} data={actives} pagination />
                  </div>
                  <div>
                    <button
                      className="continue w-auto mx-auto"
                      onClick={() => handleDownload("openOrder")}
                    >
                      Download CSV
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
