// import config
import axios, { handleResp } from "../config/axios";
import {
  SET_BOT_STATUS,
  SET_BOT_TYPE,
  SET_BOT_EDIT,
  SET_BOT_ID,
} from "../constant";

export const getpairs = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/tradepairs`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTradeManualPair = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/trade-manual-pairs`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getRecentOrders = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/manual-recent-orders`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getManualPosition = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/trade-manual-position`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      pairDetails: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const setBotStatus = (data) => {
  return {
    type: SET_BOT_STATUS,
    data,
  };
};

export const setBotEdit = (data) => {
  return {
    type: SET_BOT_EDIT,
    data,
  };
};

export const setBotType = (data) => {
  return {
    type: SET_BOT_TYPE,
    data,
  };
};

export const reCycleSellOrder = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/cycle-order-place`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getOpenOrders = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getOpenOrders`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      data: respData.data.data,
      pairDetails: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getOpenOrdersChart = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getOpenOrdersChart`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      data: respData.data.data,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getViewChart = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getViewChart`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      data: respData.data.data,
      pId: respData.data.pId,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getBotHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getBotHistory`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      pairDetails: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const cancelOrder = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/cancel-order`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getTradeHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getTradeHistory`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getpairssingle = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/tradepairssingle`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      Orders: respData.data.Orders,
      actPair: respData.data.actPair,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getGenTradeSet = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/gen-trade`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      type: respData.data.type,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const comManBuyOrder = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/com-manual-buy`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const comManSellOrder = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/com-manual-sell`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getTotQuant = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-total-quant`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const changeTradeStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/changeTradeStatus`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getStrategyPeriod = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/strategyperiod`,
      data,
    });
    // console.log(respData, "respData----------------");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const AddStrategyPeriod = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/strategyperiod`,
      data,
    });
    // console.log(respData,'--------------------------respData111');
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const dcabotValidation = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/dca-bot-validation`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    console.log("----636", err.response.data.message);
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const GenieOrderPlace = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/genie-orderplace`,
      data: data,
    });
    console.log(respData, "------501");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    console.log("----636", err.response.data.message);
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const manualOrderPlace = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/manual-orderplace`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const bulkEdit = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/set-bulk-edit`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getPositionData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/check-position`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const currentMarketPrice = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/currentMarketPrice`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getDashpairs = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/DashPair`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getShareModal = async (data) => {
  try {
    // console.log(data, "-------33");
    let respData = await axios({
      method: "get",
      url: `/api/getsharemodal`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getOpenOrdersAll = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getOpenOrdersAll`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      pairDetails: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTradeHistoryAll = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getTradeHistoryAll`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGenTradeSetUser = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/gen-trade-user`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      tradeSettings: respData.data.tradeSettings,
      type: respData.data.type,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const ModifyBot = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/modify-bot`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMarketPrice = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMarketPrice`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMarketPriceexchange = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMarketPriceexchange`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const dcaBacktest = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/dca-backtest`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTradeHistoryopen = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getTradeHistoryopen`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const gridBacktest = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid-backtest`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getexchangeinfo = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getexchangeinfo`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      pairName: data.pair,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getlockedAmt = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/lockedamt`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const setBotId = (data) => {
  return {
    type: SET_BOT_ID,
    data,
  };
};

export const getfetchrunBot = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/pairrunOtherBotapi`,
      data: data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getfetchrunBotdca = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/pairrunOtherBotapidca`,
      data: data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
